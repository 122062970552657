import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, getContext, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RemoveCityKeyPayload } from './types.ts'

export function* handleRemoveCacheWithSaveCities(): SagaIterator {
    try {
        yield call(api.removeCacheWithSaveCities)
        yield put(actions.removeCacheWithSaveCitiesSuccess())
        const notification = yield getContext('notification')
        notification.open({
            type: 'success',
            message: 'Очистка кеша',
            description:
                'Кеш с сохарнением городов и папок верхнего уровня, был успешно очищен'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeCacheWithSaveCitiesFailure({
                error: message
            })
        )
    }
}

export function* watchHandleRemoveCacheWithSaveCities(): SagaIterator {
    yield takeEvery(
        actions.removeCacheWithSaveCities,
        handleRemoveCacheWithSaveCities
    )
}

export function* handleFetchListCities(): SagaIterator {
    try {
        const { data } = yield call(api.fetchListCities)
        yield put(actions.fetchListCitiesSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchListCitiesFailure({
                error: message
            })
        )
    }
}

export function* watchHandleFetchListCities(): SagaIterator {
    yield takeEvery(actions.fetchListCities, handleFetchListCities)
}

export function* handleRemoveCityKey(
    action: PayloadAction<RemoveCityKeyPayload>
): SagaIterator {
    try {
        yield call(api.removeCityKey, action.payload.key)
        yield put(actions.removeCityKeySuccess())
        const notification = yield getContext('notification')
        notification.open({
            type: 'success',
            message: 'Очистка ключа кеша',
            description: `Данные с ключом ${action.payload.key} успешно удалены`
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeCityKeyFailure({
                error: message
            })
        )
    }
}

export function* watchHandleRemoveCityKey(): SagaIterator {
    yield takeEvery(actions.removeCityKey, handleRemoveCityKey)
}

export function* handleRemoveCompaniesPages(): SagaIterator {
    try {
        yield call(api.removeCompaniesPages)
        yield put(actions.removeCompaniesPagesSuccess())
        const notification = yield getContext('notification')
        notification.open({
            type: 'success',
            message: 'Очистка старых страниц компаний',
            description:
                'Старые страницы компаний были успешно удалены из папок верхнего уровня'
        })
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeCompaniesPagesFailure({
                error: message
            })
        )
    }
}

export function* watchHandleRemoveCompaniesPages(): SagaIterator {
    yield takeEvery(actions.removeCompaniesPages, handleRemoveCompaniesPages)
}

export default function* watchSettingsCache(): SagaIterator {
    yield all([
        fork(watchHandleRemoveCacheWithSaveCities),
        fork(watchHandleFetchListCities),
        fork(watchHandleRemoveCityKey),
        fork(watchHandleRemoveCompaniesPages)
    ])
}
