import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { Modal, InputNumber } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsFairsSelector } from '../../containers/SettingsFairs/selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsFairs/slice.ts'

export default function CountModal(): ReactElement {
    const [value, setValue] = useState(0)

    const { count, isFetchingCount, isCountEdit, isCountModal } =
        useAppSelector(settingsFairsSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.fetchCount())
    }, [])

    useEffect(() => {
        setValue(count)
    }, [count])

    return (
        <Modal
            open={isCountModal}
            title={'Настройка счетчика ярмарки'}
            onCancel={() => dispatch(actions.toggleCountModal(false))}
            okText={'Сохранить'}
            onOk={() => dispatch(actions.setCount({ count: value }))}
            okButtonProps={{
                loading: isFetchingCount || isCountEdit
            }}
        >
            <InputNumber
                value={value}
                onInput={number => setValue(parseInt(number) ?? 0)}
                placeholder={'Введите кол-во посещений'}
                style={{ width: '100%' }}
                decimalSeparator={'.'}
                disabled={isFetchingCount || isCountEdit}
            />
        </Modal>
    )
}
