import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import type { SelectProps } from 'antd'
import { TreeSelect } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { eventCategoriesSelector } from './selectors.ts'
import type { ICategory } from './types.ts'

const { SHOW_PARENT } = TreeSelect

interface ITreeDataItem {
    label: string
    value: string
    children?: ITreeDataItem[]
    selectable?: boolean
}

export default function EventCategoriesSelector(
    props: { isSingleMode?: boolean } & SelectProps
): ReactElement {
    const { data, isFetching } = useAppSelector(eventCategoriesSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    const mapTreeData = (item: ICategory, level: number = 1): ITreeDataItem => {
        const result: ITreeDataItem = {
            label: item.name,
            value: item.eventCategoryId,
            selectable: props.isSingleMode ? level > 1 : true
        }
        if (
            Array.isArray(item.children) &&
            (props.isSingleMode ? level < 2 : true)
        ) {
            result.children = item.children.map(child =>
                mapTreeData(child, level + 1)
            )
        }
        return result
    }

    return (
        <TreeSelect
            {...props}
            style={{ width: '100%' }}
            placeholder={'Категория'}
            treeCheckable={!props.isSingleMode}
            showCheckedStrategy={props.isSingleMode ? undefined : SHOW_PARENT}
            allowClear={true}
            disabled={isFetching}
            loading={isFetching}
            treeData={data.map(item => mapTreeData(item))}
        />
    )
}
