import type { AxiosResponse } from 'axios'
import axios from 'axios'

export const removeCacheWithSaveCities = (): Promise<AxiosResponse> =>
    axios(`${process.env.SITE_URL}/api/redis-reset-save-cities`, {
        method: 'POST',
        headers: {
            'x-api-key': process.env.INSIDE_API_KEY,
            'Access-Control-Allow-Origin': '*'
        }
    })

export const fetchListCities = (): Promise<AxiosResponse> =>
    axios(`${process.env.SITE_URL}/api/get-cities-redis`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.INSIDE_API_KEY
        }
    })

export const removeCityKey = (key: string): Promise<AxiosResponse> =>
    axios(`${process.env.SITE_URL}/api/remove-city-key-redis`, {
        method: 'POST',
        data: { key },
        headers: {
            'x-api-key': process.env.INSIDE_API_KEY
        }
    })

export const removeCompaniesPages = (): Promise<AxiosResponse> =>
    axios(`${process.env.SITE_URL}/api/clean-cities-redis`, {
        method: 'GET',
        headers: {
            'x-api-key': process.env.INSIDE_API_KEY
        }
    })
