import type { CSSProperties, ReactElement } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { Row, TreeSelect } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { tagsSelector } from './selectors.ts'

const { SHOW_PARENT } = TreeSelect

interface Props {
    initialValue?: string[]
    onChange: (value: string[]) => void
    placeholder?: string
    isOneCategory?: boolean
    selectedCategory?: string
    style?: CSSProperties
    disabled?: boolean
}

export default function TagsSelector({
    initialValue,
    onChange,
    isOneCategory,
    selectedCategory,
    style,
    disabled
}: Props): ReactElement {
    const [isLoaded, setIsLoaded] = useState(false)
    const [isTouched, setIsTouched] = useState(false)
    const { data, isFetching } = useAppSelector(tagsSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        if (isTouched && isOneCategory) {
            onChange([])
        }
        if (selectedCategory) {
            setIsTouched(true)
        }
    }, [selectedCategory])

    useEffect(() => {
        if (data.length > 0) {
            setIsLoaded(true)
        }
    }, [data])

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <TreeSelect
            value={isLoaded && initialValue ? initialValue : undefined}
            onChange={onChange}
            style={style ?? { width: '100%' }}
            disabled={disabled}
            placeholder={'Выберите теги'}
            allowClear={true}
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
            treeDefaultExpandAll={isOneCategory}
            loading={isFetching}
            notFoundContent={
                isOneCategory ? (
                    <p style={{ textAlign: 'center' }}>
                        {'В выбранной категории теги не найдены'}
                    </p>
                ) : (
                    ''
                )
            }
            treeData={data
                .filter(category =>
                    isOneCategory
                        ? category.post_category_id === selectedCategory ||
                          selectedCategory?.includes(category.post_category_id)
                        : true
                )
                .map(category => ({
                    label: (
                        <Row align={'middle'}>
                            {category.picture ? (
                                <img
                                    width={12}
                                    height={12}
                                    style={{ marginRight: 10 }}
                                    src={category.picture.original}
                                />
                            ) : null}
                            {category.name}
                        </Row>
                    ),
                    checkable: false,
                    value: category.post_category_id,
                    children: category.tags.map(tag => ({
                        label: tag.title,
                        value: tag.post_tag_id
                    }))
                }))}
        />
    )
}
