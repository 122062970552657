import type { AxiosResponse } from 'axios'
import { get } from '../../utils/api.ts'

export const fetch = (
    date_start: string,
    date_end: string,
    user_id?: string,
    tags?: string[]
): Promise<AxiosResponse> =>
    get('/admin/blog/posts/analytics', {
        period: `${date_start},${date_end}`,
        filter: {
            user_id,
            tags
        }
    })
