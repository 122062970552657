import type { ReactElement } from 'react'
import { useEffect } from 'react'
import type { SelectProps } from 'antd'
import { Select } from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { eventTagsSelector } from './selectors.ts'

export default function EventTagsSelector(props: SelectProps): ReactElement {
    const dispatch = useDispatch()

    const { tags, isFetching } = useAppSelector(eventTagsSelector)

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <Select
            {...props}
            value={isFetching ? undefined : props.value}
            mode={'multiple'}
            allowClear={true}
            placeholder={'Выберите темы категории'}
            loading={isFetching}
            disabled={isFetching}
            options={tags.map(tag => ({
                label: tag.title,
                value: tag.event_tag_id
            }))}
        />
    )
}
