import type { AxiosResponse } from 'axios'
import type { IMeta } from '../../types'
import type { ITag } from '../SettingsCompaniesTags/types.ts'
import { get } from '../../utils/api.ts'
import type { ICategory } from '../AdvertisingWizard/types.ts'

export const fetchCategories = (): Promise<AxiosResponse<ICategory[]>> =>
    get('/company-categories', { type: 'offline' })

export const fetchTags = (): Promise<AxiosResponse<IMeta<ITag>>> =>
    get('/admin/company-tags', { page_size: 1000, sort: 'short_id' })
