import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    RemoveCacheWithSaveCitiesPayload,
    RemoveCacheWithSaveCitiesSuccessPayload,
    RemoveCacheWithSaveCitiesFailurePayload,
    FetchListCitiesPayload,
    FetchListCitiesSuccessPayload,
    FetchListCitiesFailurePayload,
    RemoveCityKeyPayload,
    RemoveCityKeySuccessPayload,
    RemoveCityKeyFailurePayload,
    RemoveCompaniesPagePayload,
    RemoveCompaniesPageSuccessPayload,
    RemoveCompaniesPageFailurePayload
} from './types'

const initialState: InitialStateType = {
    isRemoveCacheWithSaveCities: false,
    isProcessGetCitiesAndFolders: false,
    isRemoveCitiesAndFoldersKey: false,
    isModalCitiesObject: false,
    isRemoveCompaniesPages: false,
    citiesObjectData: null,
    error: null
}

const settingsCacheSlice = createSlice({
    name: 'settingsCache',
    initialState,
    reducers: {
        removeCacheWithSaveCities(
            state: InitialStateType,
            _action: PayloadAction<RemoveCacheWithSaveCitiesPayload>
        ) {
            state.isRemoveCacheWithSaveCities = true
            state.error = null
        },
        removeCacheWithSaveCitiesSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveCacheWithSaveCitiesSuccessPayload>
        ) {
            state.isRemoveCacheWithSaveCities = false
        },
        removeCacheWithSaveCitiesFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveCacheWithSaveCitiesFailurePayload>
        ) {
            state.isRemoveCacheWithSaveCities = false
            state.error = action.payload.error
        },
        fetchListCities(
            state: InitialStateType,
            _action: PayloadAction<FetchListCitiesPayload>
        ) {
            state.isProcessGetCitiesAndFolders = true
            state.error = null
        },
        fetchListCitiesSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchListCitiesSuccessPayload>
        ) {
            state.isModalCitiesObject = true
            state.citiesObjectData = action.payload.data
            state.isProcessGetCitiesAndFolders = false
        },
        fetchListCitiesFailure(
            state: InitialStateType,
            action: PayloadAction<FetchListCitiesFailurePayload>
        ) {
            state.isProcessGetCitiesAndFolders = false
            state.error = action.payload.error
        },
        removeCityKey(
            state: InitialStateType,
            _action: PayloadAction<RemoveCityKeyPayload>
        ) {
            state.isRemoveCitiesAndFoldersKey = true
            state.error = null
        },
        removeCityKeySuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveCityKeySuccessPayload>
        ) {
            state.isRemoveCitiesAndFoldersKey = false
        },
        removeCityKeyFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveCityKeyFailurePayload>
        ) {
            state.isRemoveCitiesAndFoldersKey = false
            state.error = action.payload.error
        },
        removeCompaniesPages(
            state: InitialStateType,
            _action: PayloadAction<RemoveCompaniesPagePayload>
        ) {
            state.isRemoveCompaniesPages = true
            state.error = null
        },
        removeCompaniesPagesSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveCompaniesPageSuccessPayload>
        ) {
            state.isRemoveCompaniesPages = false
        },
        removeCompaniesPagesFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveCompaniesPageFailurePayload>
        ) {
            state.isRemoveCompaniesPages = false
            state.error = action.payload.error
        },
        toggleCityDataModal(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isModalCitiesObject = action.payload
        }
    }
})

export const actions = settingsCacheSlice.actions

export default settingsCacheSlice.reducer
