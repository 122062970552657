import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import type { SelectProps } from 'antd'
import { Row, Select } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { postCategorySelector } from './selectors.ts'

export default function PostCategorySelector(props: SelectProps): ReactElement {
    const { data, isFetching } = useAppSelector(postCategorySelector)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <Select
            {...props}
            style={{ width: '100%' }}
            placeholder={'Категория'}
            optionLabelProp={'label'}
            loading={isFetching}
            options={data.map(category => ({
                label: category.name,
                value: category.post_category_id,
                img: category.picture?.url
            }))}
            optionRender={option => (
                <Row align={'middle'}>
                    {option.data.img ? (
                        <img
                            width={12}
                            height={12}
                            style={{ marginRight: 10 }}
                            src={option.data.img}
                        />
                    ) : null}
                    {option.label}
                </Row>
            )}
        />
    )
}
