import type { AxiosResponse } from 'axios'
import { del, get, patch, post } from '../../utils/api.ts'

export const fetchFairs = (
    page: number,
    pageSize: number
): Promise<AxiosResponse<void>> =>
    get<void>('/admin/fairs', {
        page,
        pageSize,
        sort: '-created_at',
        include: 'areas'
    })

export const create = (
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> => post<void>('/admin/fairs', form)

export const edit = (
    fair_id: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> => patch<void>(`/admin/fairs/${fair_id}`, form)

export const remove = (fair_id: string): Promise<AxiosResponse<void>> =>
    del<void>(`/admin/fairs/${fair_id}`)

export const fetchCount = (): Promise<AxiosResponse<void>> =>
    get('/admin/fairs/views-count/get')

export const setCount = (count: number): Promise<AxiosResponse<void>> =>
    post('/admin/fairs/views-count/set', { count })
