import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { IMeta } from '../../types'
import type {
    CreatePayload,
    EditPayload,
    FetchPayload,
    IElement,
    RemovePayload
} from './types.ts'
import type { PayloadAction } from '@reduxjs/toolkit'
import { settingsHomeBackgroundsSelector } from './selectors.ts'

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { page, pageSize } = action.payload
        const { data }: { data: IMeta<IElement> } = yield call(
            api.fetchBackgrounds,
            page,
            pageSize
        )
        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleCreate(
    action: PayloadAction<CreatePayload>
): SagaIterator {
    try {
        yield call(api.createBackgrounds, action.payload.form)
        yield put(actions.createSuccess())
        const { meta } = yield select(settingsHomeBackgroundsSelector)
        if (meta) {
            yield put(
                actions.fetch({ page: meta.page, pageSize: meta.pageSize })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchCreate(): SagaIterator {
    yield takeEvery(actions.create, handleCreate)
}

export function* handleEdit(action: PayloadAction<EditPayload>): SagaIterator {
    try {
        const { element_id, form } = action.payload
        yield call(api.editBackgrounds, element_id, form)
        yield put(actions.editSuccess())
        const { meta } = yield select(settingsHomeBackgroundsSelector)
        if (meta) {
            yield put(
                actions.fetch({ page: meta.page, pageSize: meta.pageSize })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchEdit(): SagaIterator {
    yield takeEvery(actions.edit, handleEdit)
}

export function* handleRemove(
    action: PayloadAction<RemovePayload>
): SagaIterator {
    try {
        yield call(api.removeBackgrounds, action.payload.element_id)
        yield put(actions.removeSuccess())
        const { backgrounds, meta } = yield select(
            settingsHomeBackgroundsSelector
        )
        if (meta) {
            yield put(
                actions.fetch({
                    page:
                        backgrounds.length === 1
                            ? meta.page - 1 > 1
                                ? meta.page - 1
                                : 1
                            : meta.page,
                    pageSize: meta.pageSize
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeFailure({
                error: message
            })
        )
    }
}

export function* watchRemove(): SagaIterator {
    yield takeEvery(actions.remove, handleRemove)
}

export default function* watchSettingsHomeBackgrounds(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchCreate),
        fork(watchEdit),
        fork(watchRemove)
    ])
}
