import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { CSSProperties, FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import type { LazyLoadImageProps } from 'react-lazy-load-image-component'
import { Button, Input } from 'antd'
import { DeleteOutlined, DragOutlined } from '@ant-design/icons'

const TypedLazyLoadImage = LazyLoadImage as FC<LazyLoadImageProps>

interface Props {
    id: number
    url: string
    onRemove: (id: number) => void
    index: number
    imageUrls: string[]
    onChangeUrl: (urls: string[]) => void
}

export default function SortableItem({
    id,
    url,
    onRemove,
    index,
    imageUrls,
    onChangeUrl
}: Props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id })

    const style: CSSProperties = {
        flex: '0 0 50%',
        maxWidth: '50%',
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '0 1px',
        zIndex: isDragging ? '100' : 'auto',
        boxSizing: 'border-box',
        marginBottom: 4
    }

    const outerStyle: CSSProperties = {
        background: '#fff',
        position: 'relative',
        aspectRatio: 1 / 0.5,
        border: '1px solid #eee',
        borderRadius: 10,
        overflow: 'hidden'
    }

    const navStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        top: 5,
        right: 5,
        zIndex: 10
    }

    return (
        <div ref={setNodeRef} style={style}>
            <div style={outerStyle}>
                <div style={navStyle}>
                    <Button
                        size={'small'}
                        type={'primary'}
                        danger={true}
                        icon={<DeleteOutlined />}
                        style={{ marginRight: 4 }}
                        onClick={() => onRemove(id)}
                    />
                    <Button
                        size={'small'}
                        type={'primary'}
                        icon={<DragOutlined />}
                        style={{ cursor: 'move' }}
                        {...listeners}
                        {...attributes}
                    />
                </div>
                <TypedLazyLoadImage
                    width={'100%'}
                    src={url}
                    draggable={false}
                    style={{ position: 'relative' }}
                />
            </div>
            <Input
                placeholder={'Введите ссылку изображения'}
                value={imageUrls[index]}
                onChange={e => {
                    const value = e.target.value
                    const result = [...imageUrls]
                    result[index] = value
                    onChangeUrl(result)
                }}
            />
        </div>
    )
}
