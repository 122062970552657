import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    FetchFailurePayload,
    FetchPayload,
    FetchSuccessPayload,
    InitialStateType,
    CreatePayload,
    CreateSuccessPayload,
    CreateFailurePayload,
    SendWizardPayload,
    SendWizardSuccessPayload,
    SendWizardFailurePayload,
    CategoryRemovePayload,
    CategoryRemoveSuccessPayload,
    CategoryRemoveFailurePayload,
    SortCategoriesPayload,
    SortCategoriesSuccessPayload,
    SortCategoriesFailurePayload,
    CreateTagPayload,
    CreateTagSuccessPayload,
    CreateTagFailurePayload,
    EditTagPayload,
    EditTagSuccessPayload,
    EditTagFailurePayload,
    RemoveTagPayload,
    RemoveTagSuccessPayload,
    RemoveTagFailurePayload,
    SortTagsFailurePayload,
    SortTagsPayload,
    SortTagsSuccessPayload
} from './types'

const initialState: InitialStateType = {
    categories: [],
    tempCategories: [],
    isFetching: false,
    isSaving: false,
    isSendWizard: false,
    isModalWizard: false,
    isCategoryRemove: false,
    isSortCategoriesProcess: false,
    isModalTagWizard: false,
    isCreateTagProcess: false,
    isEditTagProcess: false,
    isRemoveTagProcess: false,
    isSortingTags: false,
    lastFetchTime: 0,
    error: null
}

const settingsTagsSlice = createSlice({
    name: 'settingsTags',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            const data = action.payload.data
                .map(category => ({
                    ...category,
                    tags: action.payload.tags
                        .filter(
                            tag =>
                                tag.post_category_id ===
                                category.post_category_id
                        )
                        .sort((a, b) =>
                            typeof a.sort === 'number' &&
                            typeof b.sort === 'number'
                                ? a.sort - b.sort
                                : 0
                        )
                }))
                .sort((a, b) => {
                    if (
                        typeof a.order === 'number' &&
                        typeof b.order === 'number'
                    ) {
                        return a.order - b.order
                    }
                    return 0
                })
            state.categories = data
            state.tempCategories = data
            state.isFetching = false
            state.lastFetchTime = Date.now()
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        save(state: InitialStateType, _action: PayloadAction<CreatePayload>) {
            state.isSaving = true
            state.error = null
        },
        saveSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateSuccessPayload>
        ) {
            state.isSaving = false
        },
        saveFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ) {
            state.isSaving = false
            state.error = action.payload.error
        },
        sendWizard(
            state: InitialStateType,
            _action: PayloadAction<SendWizardPayload>
        ) {
            state.isSendWizard = true
            state.error = null
        },
        sendWizardSuccess(
            state: InitialStateType,
            _action: PayloadAction<SendWizardSuccessPayload>
        ) {
            state.isSendWizard = false
            state.isModalWizard = false
        },
        sendWizardFailure(
            state: InitialStateType,
            action: PayloadAction<SendWizardFailurePayload>
        ) {
            state.isSendWizard = false
            state.error = action.payload.error
        },
        removeCategory(
            state: InitialStateType,
            _action: PayloadAction<CategoryRemovePayload>
        ) {
            state.isCategoryRemove = true
            state.error = null
        },
        removeCategorySuccess(
            state: InitialStateType,
            _action: PayloadAction<CategoryRemoveSuccessPayload>
        ) {
            state.isCategoryRemove = false
            state.isModalWizard = false
        },
        removeCategoryFailure(
            state: InitialStateType,
            action: PayloadAction<CategoryRemoveFailurePayload>
        ) {
            state.isCategoryRemove = false
            state.error = action.payload.error
        },
        sortCategories(
            state: InitialStateType,
            _action: PayloadAction<SortCategoriesPayload>
        ) {
            state.isSortCategoriesProcess = true
            state.error = null
        },
        sortCategoriesSuccess(
            state: InitialStateType,
            _action: PayloadAction<SortCategoriesSuccessPayload>
        ) {
            state.isSortCategoriesProcess = false
        },
        sortCategoriesFailure(
            state: InitialStateType,
            action: PayloadAction<SortCategoriesFailurePayload>
        ) {
            state.isSortCategoriesProcess = false
            state.error = action.payload.error
        },
        toggleModalWizard(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isModalWizard = action.payload
        },
        toggleTagWizardModal(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isModalTagWizard = action.payload
        },
        createTag(
            state: InitialStateType,
            _action: PayloadAction<CreateTagPayload>
        ) {
            state.isCreateTagProcess = true
            state.error = null
        },
        createTagSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateTagSuccessPayload>
        ) {
            state.isModalTagWizard = false
            state.isCreateTagProcess = false
        },
        createTagFailure(
            state: InitialStateType,
            action: PayloadAction<CreateTagFailurePayload>
        ) {
            state.isCreateTagProcess = false
            state.error = action.payload.error
        },
        editTag(
            state: InitialStateType,
            _action: PayloadAction<EditTagPayload>
        ) {
            state.isEditTagProcess = true
            state.error = null
        },
        editTagSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditTagSuccessPayload>
        ) {
            state.isModalTagWizard = false
            state.isEditTagProcess = false
        },
        editTagFailure(
            state: InitialStateType,
            action: PayloadAction<EditTagFailurePayload>
        ) {
            state.isEditTagProcess = false
            state.error = action.payload.error
        },
        removeTag(
            state: InitialStateType,
            _action: PayloadAction<RemoveTagPayload>
        ) {
            state.isRemoveTagProcess = true
            state.error = null
        },
        removeTagSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveTagSuccessPayload>
        ) {
            state.isRemoveTagProcess = false
        },
        removeTagFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveTagFailurePayload>
        ) {
            state.isRemoveTagProcess = false
            state.error = action.payload.error
        },
        sortTags(
            state: InitialStateType,
            action: PayloadAction<SortTagsPayload>
        ) {
            if (action.payload.tags) {
                const { category_id, tags } = action.payload
                const findCategory = state.categories.find(
                    category => category.post_category_id === category_id
                )
                if (findCategory) {
                    findCategory.tags = tags.map((tag, index) => ({
                        ...tag,
                        sort: index + 1
                    }))
                }
            }
            state.isSortingTags = true
        },
        sortTagsSuccess(
            state: InitialStateType,
            _action: PayloadAction<SortTagsSuccessPayload>
        ) {
            state.isSortingTags = false
        },
        sortTagsFailure(
            state: InitialStateType,
            action: PayloadAction<SortTagsFailurePayload>
        ) {
            state.isSortingTags = false
            state.error = action.payload.error
        }
    }
})

export const actions = settingsTagsSlice.actions

export default settingsTagsSlice.reducer
