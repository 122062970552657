import { all } from 'redux-saga/effects'

// Containers
import watchApplication from '../containers/Application/saga'
import watchAuthorization from '../containers/Authorization/saga'
import watchDashboard from '../containers/Dashboard/saga'
import watchCompany from '../containers/Company/saga'
import watchCompanies from '../containers/Companies/saga'
import watchEvents from '../containers/Events/saga'
import watchUsers from '../containers/Users/saga'
import watchCompanyWizard from '../containers/CompanyWizard/saga'
import watchEventWizard from '../containers/EventWizard/saga'
import watchUserSelector from '../containers/UserSelector/saga'
import watchBusinessUserSelector from '../containers/BusinessUserSelector/saga'
import watchRegionSelector from '../containers/RegionSelector/saga'
import watchAreaSelector from '../containers/AreaSelector/saga'
import watchFranchiseeSelector from '../containers/FranchiseeSelector/saga'
import watchCompanyPropertySelector from '../containers/CompanyPropertySelector/saga'
import watchCompanySelector from '../containers/CompanySelector/saga'
import watchBannersBlock from '../containers/BannersBlock/saga'
import watchContentPage from '../containers/ContentPage/saga'
import watchPromoCodes from '../containers/PromoCodes/saga'
import watchPromoCodesAgents from '../containers/PromoCodesAgents/saga'
import watchPromoCodesReport from '../containers/PromoCodesReport/saga'
import watchAdvertising from '../containers/Advertising/saga'
import watchAdvertisingWizard from '../containers/AdvertisingWizard/saga'
import watchMyCompaniesSelector from '../containers/MyCompaniesSelector/saga'
import watchCategorySelector from '../containers/CategorySelector/saga'
import watchPostCategorySelector from '../containers/PostCategorySelector/saga'
import watchBlog from '../containers/Blog/saga'
import watchBlogWizard from '../containers/BlogWizard/saga'
import watchSettingsTags from '../containers/SettingsTags/saga'
import watchTagsSelector from '../containers/TagsSelector/saga'
import watchRegionTreeSelector from '../containers/RegionTreeSelector/saga'
import watchCompanyCategoriesSelector from '../containers/CompanyCategoriesSelector/saga.ts'
import watchCitySelector from '../containers/CitySelector/saga.ts'
import watchSettingsCoverage from '../containers/SettingsCoverage/saga.ts'
import watchSettingsAssignment from '../containers/SettingsAssignment/saga.ts'
import watchBalance from '../containers/Balance/saga.ts'
import watchBlogSelector from '../containers/BlogSelector/saga.ts'
import watchSettingsCompaniesTags from '../containers/SettingsCompaniesTags/saga.ts'
import watchSettingsEventsTags from '../containers/SettingsEventsTags/saga.ts'
import watchEventTagsSelector from '../containers/EventTagsSelector/saga.ts'
import watchCompanyTagsSelector from '../containers/CompanyTagsSelector/saga.ts'
import watchReportsJournal from '../containers/ReportsJournal/saga.ts'
import watchBlocksSelector from '../containers/BlocksSelector/saga.ts'
import watchSettingsCache from '../containers/SettingsCache/saga.ts'
import watchSettingsFairsTags from '../containers/SettingsFairs/saga.ts'
import watchSettingsHomeBackgrounds from '../containers/SettingsHomeBackgrounds/saga.ts'
import watchSettingsBottomButton from '../containers/SettingsBottomButton/saga.ts'
import watchEventCategoriesSelector from '../containers/EventCategoriesSelector/saga.ts'

export default function* rootSaga(): Generator {
    yield all([
        watchApplication(),
        watchAuthorization(),
        watchDashboard(),
        watchCompanies(),
        watchEvents(),
        watchUsers(),
        watchCompany(),
        watchCompanyWizard(),
        watchEventWizard(),
        watchUserSelector(),
        watchBusinessUserSelector(),
        watchRegionSelector(),
        watchAreaSelector(),
        watchFranchiseeSelector(),
        watchCompanyPropertySelector(),
        watchCompanySelector(),
        watchBannersBlock(),
        watchContentPage(),
        watchPromoCodes(),
        watchPromoCodesAgents(),
        watchPromoCodesReport(),
        watchAdvertising(),
        watchAdvertisingWizard(),
        watchMyCompaniesSelector(),
        watchCategorySelector(),
        watchPostCategorySelector(),
        watchCompanyCategoriesSelector(),
        watchBlog(),
        watchBlogWizard(),
        watchSettingsTags(),
        watchTagsSelector(),
        watchRegionTreeSelector(),
        watchCitySelector(),
        watchSettingsCoverage(),
        watchSettingsAssignment(),
        watchBalance(),
        watchBlogSelector(),
        watchSettingsCompaniesTags(),
        watchSettingsEventsTags(),
        watchEventTagsSelector(),
        watchCompanyTagsSelector(),
        watchReportsJournal(),
        watchBlocksSelector(),
        watchSettingsCache(),
        watchSettingsFairsTags(),
        watchSettingsHomeBackgrounds(),
        watchSettingsBottomButton(),
        watchEventCategoriesSelector()
    ])
}
