import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { IMeta } from '../../types'
import type {
    CreatePayload,
    EditPayload,
    FetchPayload,
    IFair,
    RemovePayload,
    SetCountPayload
} from './types.ts'
import type { PayloadAction } from '@reduxjs/toolkit'
import { settingsFairsSelector } from './selectors.ts'

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { page, pageSize } = action.payload
        const { data }: { data: IMeta<IFair> } = yield call(
            api.fetchFairs,
            page,
            pageSize
        )
        yield put(actions.fetchSuccess({ data: data.data, meta: data.meta }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetchFairs(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleCreate(
    action: PayloadAction<CreatePayload>
): SagaIterator {
    try {
        yield call(api.create, action.payload.form)
        yield put(actions.createSuccess())
        const { meta } = yield select(settingsFairsSelector)
        if (meta) {
            yield put(
                actions.fetch({ page: meta.page, pageSize: meta.pageSize })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchHandleCreate(): SagaIterator {
    yield takeEvery(actions.create, handleCreate)
}

export function* handleEdit(action: PayloadAction<EditPayload>): SagaIterator {
    try {
        const { fair_id, form } = action.payload
        yield call(api.edit, fair_id, form)
        yield put(actions.editSuccess())
        const { meta } = yield select(settingsFairsSelector)
        if (meta) {
            yield put(
                actions.fetch({ page: meta.page, pageSize: meta.pageSize })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchHandleEdit(): SagaIterator {
    yield takeEvery(actions.edit, handleEdit)
}

export function* handleRemove(
    action: PayloadAction<RemovePayload>
): SagaIterator {
    try {
        yield call(api.remove, action.payload.fair_id)
        yield put(actions.removeSuccess())
        const { fairs, meta } = yield select(settingsFairsSelector)
        if (meta) {
            yield put(
                actions.fetch({
                    page:
                        fairs.length === 1
                            ? meta.page - 1 > 1
                                ? meta.page - 1
                                : 1
                            : meta.page,
                    pageSize: meta.pageSize
                })
            )
        }
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeFailure({
                error: message
            })
        )
    }
}

export function* watchHandleRemove(): SagaIterator {
    yield takeEvery(actions.remove, handleRemove)
}

export function* handleFetchIncrement(): SagaIterator {
    try {
        const { data } = yield call(api.fetchCount)
        yield put(actions.fetchCountSuccess({ count: data.value }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchCountFailure({
                error: message
            })
        )
    }
}

export function* watchHandleFetchIncrement(): SagaIterator {
    yield takeEvery(actions.fetchCount, handleFetchIncrement)
}

export function* handleSetIncrement(
    action: PayloadAction<SetCountPayload>
): SagaIterator {
    try {
        yield call(api.setCount, action.payload.count)
        yield put(actions.setCountSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.setCountFailure({
                error: message
            })
        )
    }
}

export function* watchHandleSetIncrement(): SagaIterator {
    yield takeEvery(actions.setCount, handleSetIncrement)
}

export default function* watchSettingsFairsTags(): SagaIterator {
    yield all([
        fork(watchFetchFairs),
        fork(watchHandleCreate),
        fork(watchHandleEdit),
        fork(watchHandleRemove),
        fork(watchHandleFetchIncrement),
        fork(watchHandleSetIncrement)
    ])
}
