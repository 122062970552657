import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { FetchPayload } from './types.ts'

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { dateStart, dateEnd, user_id, tags } = action.payload
        const { data } = yield call(
            api.fetch,
            dateStart,
            dateEnd,
            user_id,
            tags
        )
        yield put(actions.fetchSuccess({ data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export default function* watchReportsJournal(): SagaIterator {
    yield all([fork(watchFetch)])
}
