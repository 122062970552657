import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { Select } from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import type { SelectProps } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { blocksSelectorSelector } from './selectors.ts'

export default function BlocksSelector(props: SelectProps): ReactElement {
    const dispatch = useDispatch()

    const { blocks, isFetching } = useAppSelector(blocksSelectorSelector)

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <Select
            allowClear={true}
            showSearch={true}
            filterOption={(input, option) =>
                (typeof option?.label === 'string' ? option.label : '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            style={{ width: '100%' }}
            placeholder={'Выберите блок'}
            loading={isFetching}
            disabled={isFetching}
            options={blocks.map(block => ({
                label: block.caption,
                value: block.name
            }))}
            {...props}
        />
    )
}
