import type { CSSProperties, FC, ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Flex, Popconfirm, Row, Tag, Typography } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsEventsTagsSelector } from './selectors.ts'
import { LoadingStep } from '../../components/LoadingStep'
import { actions } from './slice.ts'
import { useDispatch } from 'react-redux'
import EventTagWizardModal from '../../components/EventTagWizardModal'
import type { ITag } from './types.ts'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
    closestCenter,
    DndContext,
    type DragEndEvent,
    MouseSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable
} from '@dnd-kit/sortable'

const { Title, Paragraph } = Typography

const commonStyle: CSSProperties = {
    cursor: 'move',
    transition: 'unset'
}

interface DraggableTagProps {
    index: number
    tag: ITag
}

export default function SettingsEventsTags(): ReactElement {
    const [selectedTag, setSelectedTag] = useState<ITag | undefined>()

    const { tags, isFetching } = useAppSelector(settingsEventsTagsSelector)

    const sortedTags = [...tags].sort((a, b) =>
        typeof a.sort === 'number' && typeof b.sort === 'number'
            ? a.sort - b.sort
            : 0
    )

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
    )

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        if (!over) {
            return
        }
        if (active.id !== over.id) {
            const oldIndex = sortedTags.findIndex(
                (_, index) => index + 1 === active.id
            )
            const newIndex = sortedTags.findIndex(
                (_, index) => index + 1 === over.id
            )
            dispatch(
                actions.sortTags({
                    tags: arrayMove(sortedTags, oldIndex, newIndex)
                })
            )
        }
    }

    const DraggableTag: FC<DraggableTagProps> = props => {
        const { tag, index } = props
        const {
            attributes,
            listeners,
            transform,
            transition,
            isDragging,
            setNodeRef
        } = useSortable({ id: index })

        const style = transform
            ? {
                  ...commonStyle,
                  transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
                  transition: isDragging ? 'unset' : transition
              }
            : commonStyle

        return (
            <Tag
                key={tag.event_tag_id}
                style={{
                    ...style,
                    background: 'rgb(255, 87, 166)',
                    border: 'none',
                    color: '#FFF'
                }}
                onClose={e => {
                    e.preventDefault()
                }}
                ref={setNodeRef}
                {...attributes}
                {...listeners}
            >
                {tag.title}
                <EditOutlined
                    onClick={() => {
                        setSelectedTag(tag)
                        dispatch(actions.toggleTagWizardModal(true))
                    }}
                    style={{
                        marginLeft: 5,
                        cursor: 'pointer',
                        outline: 0
                    }}
                />
                <Popconfirm
                    placement={'topLeft'}
                    title={'Вы уверены, что хотите удалить тег?'}
                    description={
                        'Важна последовательность тегов, если удалить, некоторые страницы будут отдавать 404'
                    }
                    okText={'Да'}
                    cancelText={'Нет'}
                    onConfirm={() =>
                        dispatch(
                            actions.removeTag({
                                post_tag_id: tag.event_tag_id
                            })
                        )
                    }
                >
                    <CloseOutlined style={{ color: '#FFF', outline: 0 }} />
                </Popconfirm>
            </Tag>
        )
    }

    return (
        <>
            <Row justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'Темы предложений'}
                </Title>
            </Row>
            {sortedTags.length === 0 && isFetching ? (
                <LoadingStep />
            ) : (
                <>
                    <DndContext
                        sensors={sensors}
                        onDragEnd={handleDragEnd}
                        collisionDetection={closestCenter}
                    >
                        <SortableContext
                            items={sortedTags.map((_, index) => ({
                                id: index + 1
                            }))}
                            strategy={horizontalListSortingStrategy}
                        >
                            <Flex gap={'4px 0'} style={{ marginTop: 20 }} wrap>
                                {sortedTags.map((item, index) => (
                                    <DraggableTag
                                        tag={item}
                                        index={index + 1}
                                        key={item.event_tag_id}
                                    />
                                ))}
                                <Tag
                                    style={{
                                        height: 22,
                                        borderStyle: 'dashed',
                                        cursor: 'pointer',
                                        background: 'rgba(255, 255, 255, 0.5)'
                                    }}
                                    icon={<PlusOutlined />}
                                    onClick={() =>
                                        dispatch(
                                            actions.toggleTagWizardModal(true)
                                        )
                                    }
                                >
                                    {'Новый тег'}
                                </Tag>
                            </Flex>
                        </SortableContext>
                    </DndContext>
                    <Paragraph style={{ marginTop: 20 }} type={'secondary'}>
                        {
                            'Сортировка тем в адресной строке в порядке создания тегов'
                        }
                    </Paragraph>
                </>
            )}
            <EventTagWizardModal
                selectedTag={selectedTag}
                sortedTags={sortedTags.map(tag => tag.event_tag_id)}
                onAfterClose={() => {
                    setSelectedTag(undefined)
                }}
            />
        </>
    )
}
