import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import type { GetProp, UploadFile, UploadProps } from 'antd'
import { Typography } from 'antd'
import { Input } from 'antd'
import { Row } from 'antd'
import { Image, Form, Modal, Upload, DatePicker } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { PlusOutlined } from '@ant-design/icons'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { authorizationSelector } from '../../containers/Authorization/selectors.ts'
import RegionSelector from '../../containers/RegionSelector'
import dayjs from 'dayjs'
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates
} from '@dnd-kit/sortable'
import SortableItem from './SortableItem.tsx'
import type { DragEndEvent } from '@dnd-kit/core/dist/types'
import styles from './styles.module.scss'
import { settingsHomeBackgroundsSelector } from '../../containers/SettingsHomeBackgrounds/selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsHomeBackgrounds/slice.ts'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import type { IImage } from '../../containers/Company/types.ts'
dayjs.extend(utc)
dayjs.extend(timezone)

const MOSCOW_TIMEZONE = 'Europe/Moscow'

const { RangePicker } = DatePicker
const { Text } = Typography

const MAX_IMAGES = 8

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = error => reject(error)
    })

export default function HomeBackgroundsWizardModal(): ReactElement {
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [fileList, setFileList] = useState<
        { id: number; fileId: number; url: string }[]
    >([])
    const [location, setLocation] = useState<
        { label: string; value: string }[]
    >([])
    const [globalError, setGlobalError] = useState<string[]>([])
    const [imageUrls, setImageUrls] = useState<string[]>([])

    const { selectedBackgrounds, isModalWizard } = useAppSelector(
        settingsHomeBackgroundsSelector
    )
    const { token } = useAppSelector(authorizationSelector)

    const dispatch = useDispatch()

    const [form] = useForm()

    useEffect(() => {
        if (selectedBackgrounds) {
            if (Array.isArray(selectedBackgrounds.areas)) {
                setLocation(
                    selectedBackgrounds.areas.map(area => ({
                        label: area.name,
                        value: area.area_id
                    }))
                )
            }
            if (Array.isArray(selectedBackgrounds.default_image_collection)) {
                setFileList(
                    selectedBackgrounds.default_image_collection.map(
                        (image: IImage, index) => ({
                            id: index + 1,
                            fileId: image.id,
                            url: image.url
                        })
                    )
                )
            }
            form.setFieldValue('name', selectedBackgrounds.name)
            form.setFieldValue('period', [
                dayjs(selectedBackgrounds.start_at as string),
                dayjs(selectedBackgrounds.end_at as string)
            ])
            setImageUrls(selectedBackgrounds.urls ?? [])
        } else {
            setPreviewImage('')
            setFileList([])
            setLocation([])
        }
    }, [selectedBackgrounds])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event

        if (over === null) {
            return
        }

        if (active.id !== over.id) {
            setFileList(prev => {
                const oldIndex = prev.findIndex(file => file.id === active.id)
                const newIndex = prev.findIndex(file => file.id === over.id)
                return arrayMove(prev, oldIndex, newIndex)
            })
        }
    }

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType)
        }

        setPreviewImage(file.url || (file.preview as string))
        setPreviewOpen(true)
    }

    const handleChange: UploadProps['onChange'] = ({
        fileList: newFileList
    }) => {
        setFileList(prev =>
            [
                ...prev,
                ...newFileList
                    .filter(file => !!file.response)
                    .map((file, index) => ({
                        id: prev.length + index + 1,
                        fileId: file.response.id,
                        url: file.response.url
                    }))
            ].filter(
                (item, index, self) =>
                    index === self.findIndex(t => t.fileId === item.fileId)
            )
        )
        setGlobalError([])
    }

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
                cursor: 'pointer'
            }}
            type={'button'}
        >
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{'Добавить изображение'}</div>
        </button>
    )

    const handleSendForm = (form: Record<string, unknown>) => {
        if (fileList.length === 0) {
            setGlobalError(['Необходимо выбрать изображения'])
            return
        }
        if (!Array.isArray(form.period)) {
            return
        }
        const formatForm = {
            name: form.name,
            start_at: dayjs(form.period[0].toString())
                .tz(MOSCOW_TIMEZONE)
                .utc(),
            end_at: dayjs(form.period[1].toString()).tz(MOSCOW_TIMEZONE).utc(),
            areas: location.map(item => item.value),
            default_image_collection: fileList.map(image => ({
                id: image.fileId
            })),
            urls: imageUrls
        }
        if (selectedBackgrounds) {
            dispatch(
                actions.edit({
                    element_id: selectedBackgrounds.element_id as string,
                    form: formatForm
                })
            )
        } else {
            dispatch(actions.create({ form: formatForm }))
        }
    }

    return (
        <Modal
            open={isModalWizard}
            onCancel={() => {
                if (!previewImage) {
                    dispatch(actions.toggleModalWizard({ visible: false }))
                    form.resetFields()
                }
            }}
            title={`${selectedBackgrounds ? 'Редактирование' : 'Создание'} настройки фона главной страницы`}
            okText={selectedBackgrounds ? 'Сохранить' : 'Создать'}
            onOk={form.submit}
            style={{ top: 40 }}
            destroyOnClose={true}
        >
            <Form form={form} layout={'vertical'} onFinish={handleSendForm}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <Row style={{ margin: '15px 0 10px' }} wrap>
                        <SortableContext
                            items={fileList}
                            strategy={rectSortingStrategy}
                        >
                            {fileList.map((file, index) => (
                                <SortableItem
                                    key={file.id}
                                    id={file.id}
                                    url={file.url}
                                    index={index}
                                    imageUrls={imageUrls}
                                    onChangeUrl={setImageUrls}
                                    onRemove={id =>
                                        setFileList(prev =>
                                            prev.filter(file => file.id !== id)
                                        )
                                    }
                                />
                            ))}
                            {fileList.length >= MAX_IMAGES ? null : (
                                <div className={styles.upload}>
                                    <Upload
                                        action={`${process.env.API_URL}/files`}
                                        headers={{
                                            Authorization: `Bearer ${token}`
                                        }}
                                        listType={'picture-card'}
                                        showUploadList={false}
                                        accept={'image/*'}
                                        multiple
                                        onPreview={event =>
                                            void handlePreview(event)
                                        }
                                        onChange={handleChange}
                                    >
                                        {uploadButton}
                                    </Upload>
                                </div>
                            )}
                        </SortableContext>
                    </Row>
                </DndContext>
                {previewImage && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: visible => setPreviewOpen(visible),
                            afterOpenChange: visible =>
                                !visible && setPreviewImage(''),
                            destroyOnClose: true
                        }}
                        src={previewImage}
                    />
                )}
                {fileList.length > 0 ? (
                    <Text type={'secondary'}>
                        {
                            'Если ссылка внешняя, она должна быть типа https://google.com'
                        }
                        <br />
                        {'Если внутренняя типа /[city]/eda'}
                    </Text>
                ) : null}
                <Form.Item
                    name={'name'}
                    style={{ margin: '10px 0' }}
                    label={'Название'}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={'Название'} />
                </Form.Item>
                <Form.Item
                    name={'period'}
                    style={{ margin: '10px 0' }}
                    label={'Период показа'}
                    rules={[{ required: true }]}
                >
                    <RangePicker
                        showTime
                        format={'DD.MM.YYYY HH:mm'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    label={'Геолокация'}
                    name={'location'}
                    rules={[
                        {
                            required: true,
                            message: undefined,
                            validator: () =>
                                location.length > 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          new Error(
                                              'Необходимо выбрать локацию'
                                          )
                                      )
                        }
                    ]}
                >
                    <RegionSelector
                        isMultiple={true}
                        initialValue={location}
                        onChange={value =>
                            Array.isArray(value) ? setLocation(value) : null
                        }
                    />
                    {globalError.length > 0 && (
                        <Form.ErrorList
                            className={styles.error}
                            errors={globalError}
                        />
                    )}
                </Form.Item>
            </Form>
        </Modal>
    )
}
