import { useEffect, useState } from 'react'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { companySelector } from '../../selectors'
import {
    Col,
    DatePicker,
    Select,
    Result,
    Row,
    Spin,
    Statistic,
    Tooltip,
    Typography
} from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from '../../slice'
import 'dayjs/locale/ru'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import {
    analyticsEventsNames,
    analyticsMainNames,
    analyticsSecondaryNames
} from '../../../../utils/helpers'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const { RangePicker } = DatePicker
const { Title, Paragraph } = Typography

export default function TabAnalytics() {
    const [isInitial, setIsInitial] = useState(false)
    const [companyRangeDates, setCompanyRangeDates] = useState<[Dayjs, Dayjs]>([
        dayjs().subtract(1, 'month'),
        dayjs()
    ])
    const [eventsRangeDates, setEventsRangeDates] = useState<[Dayjs, Dayjs]>([
        dayjs().subtract(1, 'month'),
        dayjs()
    ])
    const [selectedEvents, setSelectedEvents] = useState<string[]>([])

    const navigate = useNavigate()

    const {
        currentCompany,
        analyticsCompany,
        analyticsEvents,
        isFetchingCompanyAnalytics,
        isFetchingEventsAnalytics
    } = useAppSelector(companySelector)

    const dispatch = useDispatch()

    useEffect(() => {
        if (currentCompany) {
            dispatch(
                actions.fetchAnalytics({
                    only:
                        currentCompany.events &&
                        currentCompany.events?.length > 0
                            ? undefined
                            : 'company',
                    company: {
                        companyId: currentCompany.company_id,
                        dateStart: companyRangeDates[0].format('YYYYMMDD'),
                        dateEnd: companyRangeDates[1].format('YYYYMMDD')
                    },
                    events: {
                        events:
                            selectedEvents.length === 0
                                ? (currentCompany.events?.map(
                                      event => event.event_id
                                  ) ?? selectedEvents)
                                : selectedEvents,
                        dateStart: eventsRangeDates[0].format('YYYYMMDD'),
                        dateEnd: eventsRangeDates[1].format('YYYYMMDD')
                    }
                })
            )
            setIsInitial(true)
        }
    }, [])

    useEffect(() => {
        if (currentCompany && isInitial) {
            dispatch(
                actions.fetchAnalytics({
                    only: 'company',
                    company: {
                        companyId: currentCompany.company_id,
                        dateStart: companyRangeDates[0].format('YYYYMMDD'),
                        dateEnd: companyRangeDates[1].format('YYYYMMDD')
                    },
                    events: {
                        events:
                            selectedEvents.length === 0
                                ? (currentCompany.events?.map(
                                      event => event.event_id
                                  ) ?? selectedEvents)
                                : selectedEvents,
                        dateStart: eventsRangeDates[0].format('YYYYMMDD'),
                        dateEnd: eventsRangeDates[1].format('YYYYMMDD')
                    }
                })
            )
        }
    }, [companyRangeDates])

    useEffect(() => {
        if (currentCompany && isInitial) {
            dispatch(
                actions.fetchAnalytics({
                    only: 'events',
                    company: {
                        companyId: currentCompany.company_id,
                        dateStart: companyRangeDates[0].format('YYYYMMDD'),
                        dateEnd: companyRangeDates[1].format('YYYYMMDD')
                    },
                    events: {
                        events:
                            selectedEvents.length === 0
                                ? (currentCompany.events?.map(
                                      event => event.event_id
                                  ) ?? selectedEvents)
                                : selectedEvents,
                        dateStart: eventsRangeDates[0].format('YYYYMMDD'),
                        dateEnd: eventsRangeDates[1].format('YYYYMMDD')
                    }
                })
            )
        }
    }, [eventsRangeDates, selectedEvents])

    if (
        (isFetchingCompanyAnalytics && analyticsCompany.length === 0) ||
        !isInitial
    ) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    padding: '80px 0'
                }}
            >
                <Spin size={'large'} />
                <Paragraph
                    style={{ margin: 25, fontSize: 16 }}
                    type={'success'}
                >
                    {'Время загрузки может составлять ~3 минуты'}
                </Paragraph>
            </div>
        )
    }

    if (analyticsCompany.length === 0 || !currentCompany) {
        return <Result title={'Аналитический отчет не доступен!'} />
    }

    return (
        <Row>
            <Col span={10}>
                <Title style={{ margin: '0 0 10px' }} level={2}>
                    {'Аналитика по компании'}
                </Title>
                <Paragraph style={{ margin: '0 0 10px' }} type={'secondary'}>
                    {'Выберите период:'}
                </Paragraph>
                <Row>
                    <RangePicker
                        format={'DD.MM.YYYY'}
                        locale={locale}
                        style={{ marginBottom: 20 }}
                        allowClear={false}
                        defaultValue={companyRangeDates}
                        onChange={value =>
                            setCompanyRangeDates(value as [Dayjs, Dayjs])
                        }
                    />
                    {isFetchingCompanyAnalytics ? (
                        <Spin
                            size={'small'}
                            style={{ marginTop: 8, marginLeft: 12 }}
                        />
                    ) : null}
                    <Tooltip title={'Прочитать подробнее'}>
                        <QuestionCircleOutlined
                            onClick={() => navigate('/content/admin-analytics')}
                            style={{
                                cursor: 'pointer',
                                marginBottom: 22,
                                marginLeft: 12
                            }}
                        />
                    </Tooltip>
                </Row>
                <Paragraph
                    style={{ margin: '-16px 0 10px', fontSize: 10 }}
                    type={'success'}
                >
                    {'Время загрузки может составлять ~3 минуты'}
                </Paragraph>
                <Row gutter={16}>
                    <Col span={8}>
                        <h3>{'Общие показатели'}</h3>
                        {analyticsCompany
                            .filter(
                                i =>
                                    analyticsMainNames[
                                        i.name as keyof typeof analyticsMainNames
                                    ]
                            )
                            .map((i, index) => (
                                <Statistic
                                    key={`main-${index}`}
                                    title={
                                        analyticsMainNames[
                                            i.name as keyof typeof analyticsMainNames
                                        ]
                                    }
                                    value={i.count}
                                />
                            ))}
                    </Col>
                    <Col span={12}>
                        <h3>{'Взаимодействие через приложение Mamado'}</h3>
                        {analyticsCompany
                            .filter(
                                i =>
                                    analyticsSecondaryNames[
                                        i.name as keyof typeof analyticsSecondaryNames
                                    ]
                            )
                            .map((i, index) => (
                                <Statistic
                                    key={`secondary-${index}`}
                                    title={
                                        analyticsSecondaryNames[
                                            i.name as keyof typeof analyticsSecondaryNames
                                        ]
                                    }
                                    value={i.count}
                                />
                            ))}
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                {currentCompany.events && currentCompany.events.length > 0 ? (
                    <>
                        <Title style={{ margin: '0 0 10px' }} level={2}>
                            {'Аналитика предложений'}
                        </Title>
                        <Paragraph
                            style={{ margin: '0 0 10px' }}
                            type={'secondary'}
                        >
                            {'Выберите период:'}
                        </Paragraph>
                        <Row>
                            <RangePicker
                                format={'DD.MM.YYYY'}
                                locale={locale}
                                style={{ marginBottom: 20 }}
                                allowClear={false}
                                defaultValue={eventsRangeDates}
                                onChange={value =>
                                    setEventsRangeDates(value as [Dayjs, Dayjs])
                                }
                            />
                            {isFetchingEventsAnalytics ? (
                                <Spin
                                    size={'small'}
                                    style={{ marginTop: 8, marginLeft: 12 }}
                                />
                            ) : null}
                            <Tooltip title={'Прочитать подробнее'}>
                                <QuestionCircleOutlined
                                    onClick={() =>
                                        navigate('/content/admin-analytics')
                                    }
                                    style={{
                                        cursor: 'pointer',
                                        marginBottom: 22,
                                        marginLeft: 12
                                    }}
                                />
                            </Tooltip>
                        </Row>
                        <Paragraph
                            style={{ margin: '-16px 0 10px', fontSize: 10 }}
                            type={'success'}
                        >
                            {'Время загрузки может составлять ~3 минуты'}
                        </Paragraph>
                        <Select
                            value={selectedEvents}
                            mode={'multiple'}
                            style={{ width: 300 }}
                            placeholder={'Выбрать конкретные предложения'}
                            allowClear={true}
                            options={
                                currentCompany?.events?.map(event => ({
                                    value: event.event_id,
                                    label: event.name
                                })) ?? []
                            }
                            onChange={setSelectedEvents}
                        />
                        <Row gutter={16}>
                            <Col span={6}>
                                <h3>{'Взаимодействие'}</h3>
                                {analyticsEvents
                                    .filter(
                                        i =>
                                            analyticsEventsNames[
                                                i.name as keyof typeof analyticsEventsNames
                                            ]
                                    )
                                    .map((i, index) => (
                                        <Statistic
                                            key={`main-${index}`}
                                            title={
                                                analyticsEventsNames[
                                                    i.name as keyof typeof analyticsEventsNames
                                                ]
                                            }
                                            value={i.count}
                                        />
                                    ))}
                            </Col>
                        </Row>
                    </>
                ) : (
                    <></>
                )}
            </Col>
        </Row>
    )
}
