import type { ReactElement } from 'react'
import { useState } from 'react'
import {
    Button,
    Card,
    Col,
    Divider,
    Input,
    Modal,
    Popconfirm,
    Row,
    Space,
    Typography
} from 'antd'
import {
    BarsOutlined,
    DatabaseOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsCacheSelector } from './selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'

const { Title } = Typography

export default function SettingsCache(): ReactElement {
    const [deleteKey, setDeleteKey] = useState<string>('')

    const {
        isRemoveCacheWithSaveCities,
        isProcessGetCitiesAndFolders,
        isRemoveCitiesAndFoldersKey,
        isModalCitiesObject,
        isRemoveCompaniesPages,
        citiesObjectData
    } = useAppSelector(settingsCacheSelector)

    const dispatch = useDispatch()

    return (
        <>
            <Title level={3} style={{ margin: 0 }}>
                {'Кеширование'}
            </Title>
            <Divider />
            <Row gutter={16} wrap>
                <Col span={8} style={{ marginBottom: 10 }}>
                    <Card
                        title={'Сброс кеша с сохранением списка городов'}
                        bordered={false}
                    >
                        <Popconfirm
                            title={'Сбросить кеш'}
                            description={'Вы уверены, что хотите сбросить кеш?'}
                            okText={'Да'}
                            cancelText={'Нет'}
                            onConfirm={() =>
                                dispatch(actions.removeCacheWithSaveCities())
                            }
                        >
                            <Button
                                type={'primary'}
                                icon={<DatabaseOutlined />}
                                loading={isRemoveCacheWithSaveCities}
                            >
                                {'Сбросить кеш'}
                            </Button>
                        </Popconfirm>
                    </Card>
                </Col>
                <Col span={8} style={{ marginBottom: 10 }}>
                    <Card
                        title={
                            'Список кешированных городов и папок верхнего уровня'
                        }
                        bordered={false}
                    >
                        <Button
                            type={'primary'}
                            icon={<BarsOutlined />}
                            loading={isProcessGetCitiesAndFolders}
                            onClick={() => dispatch(actions.fetchListCities())}
                        >
                            {'Получить список городов и папок'}
                        </Button>
                    </Card>
                </Col>
                <Col span={8} style={{ marginBottom: 10 }}>
                    <Card
                        title={
                            'Удаление кешированного ключа из списка городов и папок'
                        }
                        bordered={false}
                    >
                        <Space>
                            <Input
                                value={deleteKey}
                                onChange={event =>
                                    setDeleteKey(event.target.value)
                                }
                                style={{ width: 250 }}
                                placeholder={'Введите ключ города или папки'}
                            />
                            <Button
                                loading={isRemoveCitiesAndFoldersKey}
                                disabled={!deleteKey}
                                icon={<DeleteOutlined />}
                                type={'primary'}
                                onClick={() => {
                                    dispatch(
                                        actions.removeCityKey({
                                            key: deleteKey
                                        })
                                    )
                                    setDeleteKey('')
                                }}
                            />
                        </Space>
                    </Card>
                </Col>
                <Col span={8} style={{ marginBottom: 10 }}>
                    <Card
                        title={
                            'Очистить старые страницы компаний из папок верхнего уровня'
                        }
                        bordered={false}
                    >
                        <Popconfirm
                            title={'Очистить старые страницы компаний'}
                            description={
                                'Вы уверены, что хотите очистить старые страницы компаний из папок верхнего уровня?'
                            }
                            okText={'Да'}
                            cancelText={'Нет'}
                            onConfirm={() =>
                                dispatch(actions.removeCompaniesPages())
                            }
                        >
                            <Button
                                type={'primary'}
                                icon={<DeleteOutlined />}
                                loading={isRemoveCompaniesPages}
                            >
                                {'Очистить старые страницы компаний'}
                            </Button>
                        </Popconfirm>
                    </Card>
                </Col>
            </Row>
            <Modal
                open={isModalCitiesObject}
                title={'Список гордов и папок верхнего уровня'}
                okButtonProps={{
                    style: {
                        display: 'none'
                    }
                }}
                cancelText={'Закрыть'}
                onCancel={() => dispatch(actions.toggleCityDataModal(false))}
            >
                <pre>{JSON.stringify(citiesObjectData, null, 2)}</pre>
            </Modal>
        </>
    )
}
