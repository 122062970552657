import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    CreatePayload,
    CreateSuccessPayload,
    CreateFailurePayload,
    RemovePayload,
    RemoveSuccessPayload,
    RemoveFailurePayload,
    EditPayload,
    EditSuccessPayload,
    EditFailurePayload,
    IFair,
    FetchCountPayload,
    FetchCountSuccessPayload,
    FetchCountFailurePayload,
    SetCountPayload,
    SetCountSuccessPayload,
    SetCountFailurePayload
} from './types'

const initialState: InitialStateType = {
    fairs: [],
    meta: null,
    count: 0,
    isModalWizard: false,
    selectedFair: null,
    isSelectedCopied: false,
    isFetching: false,
    isFetchingCount: false,
    isCountEdit: false,
    isCreating: false,
    isEditing: false,
    isRemoving: false,
    isCountModal: false,
    error: null
}

const settingsFairsSlice = createSlice({
    name: 'settingsFairs',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.fairs = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        create(state: InitialStateType, _action: PayloadAction<CreatePayload>) {
            state.isCreating = true
            state.error = null
        },
        createSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateSuccessPayload>
        ) {
            state.isCreating = false
            state.isModalWizard = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ) {
            state.isCreating = false
            state.error = action.payload.error
        },
        edit(state: InitialStateType, _action: PayloadAction<EditPayload>) {
            state.isEditing = true
            state.error = null
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditSuccessPayload>
        ) {
            state.isEditing = false
            state.isModalWizard = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditFailurePayload>
        ) {
            state.isEditing = false
            state.error = action.payload.error
        },
        remove(state: InitialStateType, _action: PayloadAction<RemovePayload>) {
            state.isRemoving = true
            state.error = null
        },
        removeSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveSuccessPayload>
        ) {
            state.isRemoving = false
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveFailurePayload>
        ) {
            state.isRemoving = false
            state.error = action.payload.error
        },
        toggleModalWizard(
            state: InitialStateType,
            action: PayloadAction<{
                visible: boolean
                selectedFair?: IFair | null
                isSelectedCopied?: boolean
            }>
        ) {
            state.isModalWizard = action.payload.visible
            state.selectedFair = action.payload.selectedFair ?? null
            state.isSelectedCopied = !!action.payload.isSelectedCopied
        },
        fetchCount(
            state: InitialStateType,
            _action: PayloadAction<FetchCountPayload>
        ) {
            state.isFetchingCount = true
            state.error = null
        },
        fetchCountSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchCountSuccessPayload>
        ) {
            state.count = action.payload.count
            state.isFetchingCount = false
        },
        fetchCountFailure(
            state: InitialStateType,
            action: PayloadAction<FetchCountFailurePayload>
        ) {
            state.isFetchingCount = false
            state.error = action.payload.error
        },
        setCount(
            state: InitialStateType,
            _action: PayloadAction<SetCountPayload>
        ) {
            state.isCountEdit = true
            state.error = null
        },
        setCountSuccess(
            state: InitialStateType,
            _action: PayloadAction<SetCountSuccessPayload>
        ) {
            state.isCountEdit = false
            state.isCountModal = false
        },
        setCountFailure(
            state: InitialStateType,
            action: PayloadAction<SetCountFailurePayload>
        ) {
            state.isCountEdit = false
            state.error = action.payload.error
        },
        toggleCountModal(
            state: InitialStateType,
            action: PayloadAction<boolean>
        ) {
            state.isCountModal = action.payload
        }
    }
})

export const actions = settingsFairsSlice.actions

export default settingsFairsSlice.reducer
