import type { ReactElement } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {
    Col,
    DatePicker,
    Divider,
    Result,
    Row,
    Spin,
    Statistic,
    Typography
} from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { reportsJournalSelector } from './selectors.ts'
import { reportsJournalNames } from '../../utils/helpers.ts'
import locale from 'antd/es/date-picker/locale/ru_RU'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import UserSelector from '../UserSelector'
import TagsSelector from '../TagsSelector'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const { Title, Paragraph } = Typography
const { RangePicker } = DatePicker

export default function ReportsJournal(): ReactElement {
    const [rangeDates, setRangeDates] = useState<[Dayjs, Dayjs]>([
        dayjs().subtract(1, 'month'),
        dayjs()
    ])
    const [selectedUser, setSelectedUser] = useState<string>('')
    const [selectedTags, setSelectedTags] = useState<string[]>([])

    const dispatch = useDispatch()

    const { data, isFetching } = useAppSelector(reportsJournalSelector)

    useEffect(() => {
        dispatch(
            actions.fetch({
                dateStart: rangeDates[0].format('YYYY-MM-DD'),
                dateEnd: rangeDates[1].format('YYYY-MM-DD'),
                user_id: selectedUser
                    ? selectedUser.split('{d}')[1]
                    : undefined,
                tags: selectedTags.length > 0 ? selectedTags : undefined
            })
        )
    }, [rangeDates, selectedUser, selectedTags])

    if (isFetching && !data) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    padding: '80px 0'
                }}
            >
                <Spin size={'large'} />
                <Paragraph
                    style={{ margin: 25, fontSize: 16 }}
                    type={'success'}
                >
                    {'Время загрузки может составлять ~3 минуты'}
                </Paragraph>
            </div>
        )
    }

    if (!data) {
        return <Result title={'Отчет не доступен!'} />
    }

    return (
        <>
            <Title level={3} style={{ margin: 0 }}>
                {'Отчет журнала'}
            </Title>
            <Divider />
            <Paragraph style={{ margin: '0 0 10px' }} type={'secondary'}>
                {'Выберите период:'}
            </Paragraph>
            <Row>
                <RangePicker
                    format={'DD.MM.YYYY'}
                    locale={locale}
                    style={{ width: 300, marginBottom: 10 }}
                    allowClear={false}
                    defaultValue={rangeDates}
                    onChange={value => setRangeDates(value as [Dayjs, Dayjs])}
                />
                {isFetching ? (
                    <Spin
                        size={'small'}
                        style={{ marginTop: 8, marginLeft: 12 }}
                    />
                ) : null}
            </Row>
            <Paragraph style={{ margin: '0 0 10px' }} type={'secondary'}>
                {'Пользователь:'}
            </Paragraph>
            <UserSelector
                style={{ width: 300 }}
                initialValue={selectedUser}
                onChange={setSelectedUser}
            />
            <Paragraph style={{ margin: '10px 0 10px' }} type={'secondary'}>
                {'Теги:'}
            </Paragraph>
            <TagsSelector
                style={{ width: 300 }}
                initialValue={selectedTags}
                onChange={setSelectedTags}
            />
            <Col style={{ marginTop: 20 }} span={6}>
                {Object.keys(data).map((key, index) => (
                    <Statistic
                        key={`main-${index}`}
                        title={
                            reportsJournalNames[
                                key as keyof typeof reportsJournalNames
                            ]
                        }
                        value={data[key as keyof typeof data]}
                    />
                ))}
            </Col>
        </>
    )
}
