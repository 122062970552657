import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { memo, useState } from 'react'
import {
    Affix,
    Anchor,
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Spin,
    Typography,
    Upload,
    DatePicker,
    Checkbox,
    Modal
} from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'
import {
    ArrowLeftOutlined,
    CheckOutlined,
    EditOutlined,
    InboxOutlined
} from '@ant-design/icons'
import UserSelector from '../UserSelector'
import FranchiseeSelector from '../FranchiseeSelector'
import MyCompaniesSelector from '../MyCompaniesSelector'
import { useEditorJS } from '../../hooks/useEditorJS.ts'
import CyrillicToTranslit from 'cyrillic-to-translit-js'
import { useForm } from 'antd/es/form/Form'
import ImgCrop from 'antd-img-crop'
import BannersBlock from '../BannersBlock'
import { useDispatch } from 'react-redux'
import type { IForm } from './types.ts'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { blogWizardSelector } from './selectors.ts'
import type { OutputData } from '@editorjs/editorjs'
import { getCompanySlug, getRole } from '../../utils/helpers.ts'
import { authorizationSelector } from '../Authorization/selectors.ts'
import TagsSelector from '../TagsSelector'
import dayjs from 'dayjs'
import RegionTreeSelector from '../RegionTreeSelector'
import PostCategorySelector from '../PostCategorySelector'

import utc from 'dayjs/plugin/utc'
import BlogSelector from '../BlogSelector'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const MOSCOW_TIMEZONE = 'Europe/Moscow'

const MemoBannersBlock = memo(
    BannersBlock,
    (prevProps, nextProps) => prevProps.code === nextProps.code
)

const { Title, Paragraph } = Typography
const { TextArea } = Input
const { Dragger } = Upload

interface Props {
    isCreate?: boolean
}

export default function BlogWizard({ isCreate }: Props): ReactElement {
    const [isUrlEditable, setIsUrlEditable] = useState(true)
    const [editorData, setEditorData] = useState<OutputData | undefined>()
    const [companySelector, setCompanySelector] = useState('')
    const [delayedPublication, setDelayedPublication] = useState(false)
    const [isExitModal, setIsExitModal] = useState(false)

    const [areasSelector, setAreasSelector] = useState<
        { label: string; value: string }[]
    >([])

    const [relevantPosts, setRelevantsPosts] = useState<
        { label: string; value: string }[]
    >([])

    const { profile } = useAppSelector(authorizationSelector)
    const { currentPost, currentCover, isProcess, isUploadCover } =
        useAppSelector(blogWizardSelector)

    const [api, contextHolder] = notification.useNotification()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const isAdmin = profile ? getRole(profile.roles) === 'admin' : false

    const isAdminOrFranchisee = profile
        ? isAdmin || getRole(profile.roles) === 'franchisee'
        : false

    const [form] = useForm()

    const watchUrl = Form.useWatch('slug', form)
    const watchTags = Form.useWatch('tags', form)
    const watchPostCategories = Form.useWatch('post_categories', form)

    useEffect(() => {
        if (profile) {
            if (isAdminOrFranchisee) {
                form.setFieldValue(
                    'user_id',
                    `${[profile.full_name, profile.phone, profile.email].filter(Boolean).join(' - ')}{d}${profile.user_id}`
                )
            }
        }
    }, [profile])

    // @ts-ignore
    const cyrillicToTranslit = new CyrillicToTranslit({
        preset: 'ru'
    })

    const editor = useEditorJS({
        settings: {
            holder: 'editor',
            autofocus: false,
            data: editorData
        },
        dep: editorData?.time,
        ignoreTools: isAdminOrFranchisee ? [] : ['companiesSelector']
    })

    useEffect(() => {
        if (!isCreate && currentPost) {
            const {
                title,
                slug,
                meta_title,
                meta_description,
                admin_message,
                main_photo_description,
                only_in_company,
                content,
                is_paid,
                user,
                franchisee,
                company,
                areas,
                post_tags,
                paid_date,
                status,
                post_categories,
                published_at,
                additional_views_count,
                relevant_posts,
                is_delayed
            } = currentPost

            const parseContent = content ? JSON.parse(content) : null

            if (parseContent) {
                setEditorData(parseContent)
            }

            if (company) {
                setCompanySelector(
                    `${process.env.SITE_URL}/${getCompanySlug(company.slug, company.short_id, company.company_id)}{d}${company.company_id}`
                )
            }

            if (!isAdminOrFranchisee && admin_message) {
                api.destroy()
                api.open({
                    type: 'warning',
                    message: 'Сообщение от администратора',
                    description: admin_message,
                    placement: 'bottomRight',
                    duration: null,
                    btn: (
                        <Button
                            size={'small'}
                            type={'primary'}
                            onClick={() => {
                                if (currentPost) {
                                    api.destroy()
                                    dispatch(
                                        actions.clearAdminMessage({
                                            postId: currentPost.post_id
                                        })
                                    )
                                }
                            }}
                        >
                            {'Принято'}
                        </Button>
                    )
                })
            }

            form.setFieldsValue({
                title,
                slug,
                meta_title,
                meta_description,
                admin_message,
                main_photo_description,
                only_in_company: only_in_company,
                is_paid: is_paid ? '1' : '0',
                user_id: user
                    ? `${[user.full_name, user.phone, user.email].filter(Boolean).join(' - ')}{d}${user.user_id}`
                    : undefined,
                franchisee_id: franchisee
                    ? `${franchisee.name}{d}${franchisee.franchisee_id}`
                    : undefined,
                tags: post_tags
                    ? post_tags.map(area => area.post_tag_id)
                    : undefined,
                paid_date: paid_date
                    ? dayjs(paid_date).tz(MOSCOW_TIMEZONE)
                    : undefined,
                status: status.toString(),
                post_categories: Array.isArray(post_categories)
                    ? // @ts-ignore
                      post_categories.map(category => category.post_category_id)
                    : undefined,
                published_at: published_at
                    ? dayjs(published_at).tz(MOSCOW_TIMEZONE)
                    : undefined,
                additional_views_count
            })

            setDelayedPublication(!!is_delayed)

            if (Array.isArray(areas) && areas.length > 0) {
                setAreasSelector(
                    areas.map(area => ({
                        label: area.name,
                        value: area.area_id
                    }))
                )
            }

            if (Array.isArray(relevant_posts) && relevant_posts.length > 0) {
                setRelevantsPosts(
                    relevant_posts.map(relevant => ({
                        label: relevant.title,
                        value: relevant.post_id
                    }))
                )
            }

            setIsUrlEditable(false)
        }

        return () => {
            api.destroy()
        }
    }, [currentPost])

    const handleSendForm = async (form: IForm) => {
        if (editor) {
            const content = await editor.save()

            const selectorKeys: {
                [key: string]: string | undefined
            } = {
                user_id: undefined,
                franchisee_id: undefined,
                company_id: undefined
            }

            for (const key in form) {
                if (key in selectorKeys) {
                    selectorKeys[key] = form[key as keyof typeof form]
                        ? // @ts-ignore
                          form[key as keyof typeof form]?.split('{d}')[1]
                        : undefined
                }
            }

            const formData = {
                ...form,
                meta_title: form.meta_title,
                meta_description: form.meta_description,
                admin_message: form.admin_message || null,
                content: JSON.stringify(content),
                user_id: selectorKeys.user_id,
                franchisee_id: selectorKeys.franchisee_id ?? null,
                company_id: companySelector
                    ? companySelector.split('{d}')[1]
                    : null,
                image: currentCover
                    ? {
                          id: currentCover.id
                      }
                    : undefined,
                areas:
                    areasSelector.length > 0
                        ? areasSelector.map(item => item.value)
                        : [],
                paid_date: dayjs.isDayjs(form.paid_date)
                    ? dayjs
                          .tz(
                              form.paid_date.format('YYYY-MM-DD HH:mm'),
                              MOSCOW_TIMEZONE
                          )
                          .utc()
                    : null,
                tags: form.tags ? form.tags : [],
                post_categories:
                    typeof form.post_categories === 'string'
                        ? [form.post_categories]
                        : Array.isArray(form.post_categories)
                          ? form.post_categories
                          : [],
                published_at: dayjs.isDayjs(form.published_at)
                    ? dayjs
                          .tz(
                              form.published_at.format('YYYY-MM-DD HH:mm'),
                              MOSCOW_TIMEZONE
                          )
                          .utc()
                    : null,
                relevant_posts: relevantPosts.map(item => item.value),
                is_delayed: delayedPublication
            }

            if (isCreate) {
                dispatch(
                    actions.create({
                        form: {
                            ...formData,
                            published_at: delayedPublication
                                ? formData.published_at
                                : undefined
                        }
                    })
                )
            } else if (currentPost) {
                dispatch(
                    actions.edit({
                        postId: currentPost.post_id,
                        form: {
                            ...formData,
                            published_at: delayedPublication
                                ? formData.published_at
                                : null
                        },
                        onSuccessEdit: () => setIsExitModal(true)
                    })
                )
            }
        }
    }

    return (
        <>
            <Row justify={'space-between'} align={'middle'}>
                <Col span={8}>
                    <Title level={3} style={{ margin: 0 }}>
                        {isCreate ? 'Создание статьи' : 'Редактирование статьи'}
                    </Title>
                    <Row>
                        <Link className={styles.link} to={'/blog'}>
                            <ArrowLeftOutlined
                                style={{ color: '#ff57a5', marginRight: 5 }}
                            />
                            {'Вернуться к списку'}
                        </Link>
                    </Row>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 20 }} />
            <Row gutter={[20, 20]}>
                <Col span={4}>
                    <Affix>
                        <div>
                            <div>
                                <Anchor
                                    affix={false}
                                    bounds={240}
                                    targetOffset={50}
                                    items={[
                                        {
                                            key: '1',
                                            href: `${location.pathname}#constructor`,
                                            title: 'Сборка статьи'
                                        },
                                        {
                                            key: '2',
                                            href: `${location.pathname}#settings`,
                                            title: 'Настройка отображения'
                                        }
                                    ]}
                                />
                            </div>
                            <MemoBannersBlock
                                hideCaption={true}
                                isVertical={true}
                                code={'admin-blog-wizard'}
                                style={{ marginTop: 120 }}
                            />
                        </div>
                    </Affix>
                </Col>
                <Col span={14}>
                    <Form
                        form={form}
                        initialValues={{
                            status: '1',
                            only_in_company: 'company',
                            is_paid: '1'
                        }}
                        layout={'vertical'}
                        onFinish={form => void handleSendForm(form)}
                    >
                        <div className={styles.block} id={'constructor'}>
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Сборка статьи'}
                            </Title>
                            <Form.Item
                                label={'Заголовок'}
                                name={'title'}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Необходимо заполнить заголовок'
                                    }
                                ]}
                                style={{ marginBottom: 10 }}
                            >
                                <Input
                                    placeholder={'Заголовок'}
                                    onInput={({ target }) =>
                                        isUrlEditable
                                            ? form.setFieldValue(
                                                  'slug',
                                                  cyrillicToTranslit
                                                      .transform(
                                                          (
                                                              target as EventTarget & {
                                                                  value: string
                                                              }
                                                          ).value,
                                                          '_'
                                                      )
                                                      .replace(
                                                          /[^a-zA-Z0-9-]+/g,
                                                          '-'
                                                      )
                                                      .replace(/--+/g, '-')
                                                      .replace(/^-+|-+$/g, '')
                                                      .toLowerCase()
                                              )
                                            : null
                                    }
                                    maxLength={225}
                                    style={{
                                        fontSize: 20,
                                        fontWeight: '700'
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'slug'}
                                label={'Адрес страницы'}
                                style={{ marginBottom: 15 }}
                                normalize={value =>
                                    value
                                        .replace(/[^a-zA-Z0-9-]+/g, '-')
                                        .replace(/--+/g, '-')
                                        .replace(/^-+/g, '')
                                        .toLowerCase()
                                }
                            >
                                <Input
                                    placeholder={'Адрес страницы'}
                                    onInput={() => setIsUrlEditable(false)}
                                    maxLength={
                                        currentPost?.slug &&
                                        currentPost.slug.length > 80
                                            ? currentPost.slug.length
                                            : 80
                                    }
                                    onBlur={() => {
                                        const value = form.getFieldValue('slug')
                                        if (typeof value === 'string') {
                                            form.setFieldValue(
                                                'slug',
                                                value.replace(/-$/, '')
                                            )
                                        }
                                    }}
                                />
                            </Form.Item>
                            {watchUrl ? (
                                <Paragraph
                                    copyable={true}
                                    className={styles.url}
                                >
                                    {`${process.env.SITE_URL}/journal/${watchUrl}${currentPost ? `-id${currentPost?.short_id}` : ''}`}
                                </Paragraph>
                            ) : null}
                            <Form.Item
                                name={'meta_title'}
                                label={'Meta-Title'}
                                style={{ marginBottom: 15 }}
                            >
                                <Input
                                    placeholder={'Meta-Title'}
                                    maxLength={225}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'meta_description'}
                                label={'Meta-Description'}
                                style={{ marginBottom: 15 }}
                            >
                                <TextArea placeholder={'Meta-Description'} />
                            </Form.Item>
                            <ImgCrop
                                aspect={1 / 0.32}
                                rotationSlider={false}
                                modalTitle={'Выбрать область'}
                                modalOk={'Выбрать'}
                            >
                                <Dragger
                                    className={styles.upload}
                                    showUploadList={false}
                                    accept={'image/*'}
                                    maxCount={1}
                                    multiple={false}
                                    style={{ marginBottom: 15 }}
                                    disabled={isUploadCover}
                                    beforeUpload={image => {
                                        dispatch(actions.uploadCover({ image }))
                                        return false
                                    }}
                                >
                                    {isUploadCover ? (
                                        <div
                                            className={styles['loading-cover']}
                                        >
                                            <Spin size={'large'} />
                                        </div>
                                    ) : null}
                                    {currentCover ? (
                                        <img
                                            style={{ width: '100%' }}
                                            src={currentCover.url}
                                        />
                                    ) : (
                                        <>
                                            <p
                                                className={
                                                    'ant-upload-drag-icon'
                                                }
                                            >
                                                <InboxOutlined />
                                            </p>
                                            <p className={'ant-upload-text'}>
                                                {'Главное изображение статьи'}
                                            </p>
                                            <p className={'ant-upload-hint'}>
                                                {
                                                    'Выберите или перенесите изображение'
                                                }
                                            </p>
                                        </>
                                    )}
                                </Dragger>
                            </ImgCrop>
                            <Form.Item
                                name={'main_photo_description'}
                                label={'Подпись к главной фотографии'}
                                style={{ marginBottom: 15 }}
                            >
                                <Input
                                    placeholder={'Подпись к главной фотографии'}
                                />
                            </Form.Item>
                            <Paragraph
                                style={{
                                    marginBottom: 10,
                                    fontSize: 25,
                                    fontWeight: 700
                                }}
                            >
                                {'Конструктор тела статьи'}
                            </Paragraph>
                            <div className={styles.editor} id={'editor'} />
                        </div>
                        <div className={styles.block} id={'settings'}>
                            <Title style={{ marginTop: 0 }} level={4}>
                                {'Настройка отображения'}
                            </Title>
                            <Form.Item
                                name={'post_categories'}
                                label={'Категория'}
                                style={{ marginBottom: 10 }}
                            >
                                <PostCategorySelector
                                    allowClear={isAdmin}
                                    mode={isAdmin ? 'multiple' : undefined}
                                    placeholder={'Категория'}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'tags'}
                                label={'Теги'}
                                style={{ marginBottom: 10 }}
                            >
                                <TagsSelector
                                    isOneCategory={true}
                                    selectedCategory={watchPostCategories}
                                    initialValue={watchTags}
                                    onChange={value =>
                                        form.setFieldValue('tags', value)
                                    }
                                />
                            </Form.Item>
                            {isAdminOrFranchisee ? (
                                <Form.Item
                                    name={'user_id'}
                                    label={'Владелец статьи'}
                                    style={{ marginBottom: 10 }}
                                >
                                    <UserSelector
                                        initialValue={form.getFieldValue(
                                            'user_id'
                                        )}
                                        onChange={value =>
                                            form.setFieldValue('user_id', value)
                                        }
                                    />
                                </Form.Item>
                            ) : null}
                            {isAdmin ? (
                                <Form.Item
                                    name={'franchisee_id'}
                                    label={'Франчайзи'}
                                    style={{ marginBottom: 10 }}
                                >
                                    <FranchiseeSelector
                                        initialValue={form.getFieldValue(
                                            'franchisee_id'
                                        )}
                                        onChange={value =>
                                            form.setFieldValue(
                                                'franchisee_id',
                                                value
                                            )
                                        }
                                    />
                                </Form.Item>
                            ) : null}
                            {isAdmin ? (
                                <>
                                    <Form.Item
                                        name={'is_paid'}
                                        label={'Платный показ'}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <Select
                                            style={{ width: 120 }}
                                            options={[
                                                { label: 'Да', value: '1' },
                                                { label: 'Нет', value: '0' }
                                            ]}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={'paid_date'}
                                        label={
                                            'Дата отключения платного показа'
                                        }
                                        tooltip={
                                            'Время необходимо указывать по Москве'
                                        }
                                        style={{ marginBottom: 10 }}
                                    >
                                        <DatePicker
                                            style={{ width: 150 }}
                                            showTime
                                            minDate={dayjs()}
                                            format={'DD.MM.YYYY HH:mm'}
                                        />
                                    </Form.Item>
                                </>
                            ) : null}
                            {isAdminOrFranchisee ? (
                                <Form.Item
                                    name={'areas'}
                                    label={'Регион показа'}
                                    style={{ marginBottom: 10 }}
                                >
                                    <RegionTreeSelector
                                        initialValue={areasSelector}
                                        onChange={setAreasSelector}
                                    />
                                </Form.Item>
                            ) : null}
                            <Form.Item
                                name={'company_id'}
                                label={'Компания для прикрепления статьи'}
                                style={{ marginBottom: 10 }}
                            >
                                <MyCompaniesSelector
                                    initialValue={companySelector}
                                    onChange={setCompanySelector}
                                    dataKey={'company_id'}
                                />
                            </Form.Item>
                            {isAdminOrFranchisee ? (
                                <Form.Item
                                    name={'only_in_company'}
                                    label={'Зона показа статьи'}
                                    style={{ marginBottom: 10 }}
                                >
                                    <Select
                                        style={{ width: 250 }}
                                        options={[
                                            ...(isAdmin
                                                ? [
                                                      {
                                                          label: 'Техническая',
                                                          value: 'technical'
                                                      }
                                                  ]
                                                : []),
                                            {
                                                label: 'В компании',
                                                value: 'company'
                                            },
                                            {
                                                label: 'В журнале Mamado',
                                                value: 'journal'
                                            },
                                            {
                                                label: 'В журнале Mamado и компании',
                                                value: 'both'
                                            }
                                        ]}
                                    />
                                </Form.Item>
                            ) : null}
                            <Form.Item
                                label={'Отложенная публикация'}
                                style={{ marginBottom: 10 }}
                                layout={'horizontal'}
                            >
                                <Checkbox
                                    checked={delayedPublication}
                                    onClick={() =>
                                        setDelayedPublication(prev => !prev)
                                    }
                                />
                            </Form.Item>
                            {delayedPublication ? (
                                <Form.Item
                                    name={'published_at'}
                                    label={'Дата отложенной публикации'}
                                    style={{ marginBottom: 10 }}
                                    tooltip={
                                        'Время необходимо указывать по Москве'
                                    }
                                >
                                    <DatePicker
                                        showTime
                                        style={{ width: 150 }}
                                        minDate={dayjs()}
                                        format={'DD.MM.YYYY HH:mm'}
                                    />
                                </Form.Item>
                            ) : null}
                            <Form.Item
                                name={'status'}
                                label={'Статус'}
                                style={{ marginBottom: 10 }}
                            >
                                <Select
                                    style={{ width: 200 }}
                                    options={[
                                        {
                                            value: '3',
                                            label: 'Опубликовано'
                                        },
                                        {
                                            value: '4',
                                            label: 'Не опубликовано'
                                        },
                                        {
                                            value: '1',
                                            label: 'Черновик'
                                        }
                                    ]}
                                />
                            </Form.Item>
                            {isAdminOrFranchisee ? (
                                <Form.Item
                                    name={'admin_message'}
                                    label={
                                        'Информационное сообщение (владельцу статьи)'
                                    }
                                    style={{ marginBottom: 10 }}
                                >
                                    <TextArea
                                        style={{
                                            width: '100%',
                                            minHeight: 100
                                        }}
                                        placeholder={
                                            'Информационное сообщение (владельцу статьи)'
                                        }
                                    />
                                </Form.Item>
                            ) : null}
                            {isAdminOrFranchisee ? (
                                <>
                                    <Form.Item
                                        name={'additional_views_count'}
                                        label={'Добавить кол-во просмотров'}
                                    >
                                        <InputNumber
                                            style={{ width: 250 }}
                                            placeholder={
                                                'Добавить кол-во просмотров'
                                            }
                                            min={0}
                                        />
                                    </Form.Item>
                                    <Form.Item label={'Статьи в Показать еще'}>
                                        <BlogSelector
                                            initialValue={relevantPosts}
                                            onChange={setRelevantsPosts}
                                        />
                                    </Form.Item>
                                </>
                            ) : null}
                        </div>
                        <Row justify={'end'}>
                            <Button
                                loading={isProcess}
                                htmlType={'submit'}
                                icon={<EditOutlined />}
                                type={'primary'}
                            >
                                {'Сохранить статью'}
                            </Button>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Modal
                open={isExitModal}
                title={
                    <>
                        <CheckOutlined
                            style={{ color: 'green', marginRight: 5 }}
                        />{' '}
                        {'Статья успешно сохранена'}
                    </>
                }
                onOk={() => {
                    navigate('/blog')
                    setIsExitModal(false)
                }}
                onCancel={() => setIsExitModal(false)}
                okText={'Уйти'}
                cancelText={'Остаться'}
            >
                {'Остаться или уйти со страницы?'}
            </Modal>
            {contextHolder}
        </>
    )
}
