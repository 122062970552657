import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload,
    CreatePayload,
    CreateSuccessPayload,
    CreateFailurePayload,
    EditPayload,
    EditSuccessPayload,
    EditFailurePayload,
    RemovePayload,
    RemoveSuccessPayload,
    RemoveFailurePayload,
    IElement
} from './types'

const initialState: InitialStateType = {
    backgrounds: [],
    meta: null,
    isModalWizard: false,
    selectedBackgrounds: null,
    isFetching: false,
    isCreating: false,
    isEditing: false,
    isRemoving: false,
    error: null
}

const settingsHomeBackgroundsSlice = createSlice({
    name: 'settingsHomeBackgrounds',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.backgrounds = action.payload.data
            state.meta = action.payload.meta
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        },
        create(state: InitialStateType, _action: PayloadAction<CreatePayload>) {
            state.isCreating = true
            state.error = null
        },
        createSuccess(
            state: InitialStateType,
            _action: PayloadAction<CreateSuccessPayload>
        ) {
            state.isModalWizard = false
            state.isCreating = false
        },
        createFailure(
            state: InitialStateType,
            action: PayloadAction<CreateFailurePayload>
        ) {
            state.isCreating = false
            state.error = action.payload.error
        },
        edit(state: InitialStateType, _action: PayloadAction<EditPayload>) {
            state.isEditing = true
            state.error = null
        },
        editSuccess(
            state: InitialStateType,
            _action: PayloadAction<EditSuccessPayload>
        ) {
            state.isModalWizard = false
            state.isEditing = false
        },
        editFailure(
            state: InitialStateType,
            action: PayloadAction<EditFailurePayload>
        ) {
            state.isEditing = false
            state.error = action.payload.error
        },
        remove(state: InitialStateType, _action: PayloadAction<RemovePayload>) {
            state.isRemoving = true
            state.error = null
        },
        removeSuccess(
            state: InitialStateType,
            _action: PayloadAction<RemoveSuccessPayload>
        ) {
            state.isModalWizard = false
            state.isRemoving = false
        },
        removeFailure(
            state: InitialStateType,
            action: PayloadAction<RemoveFailurePayload>
        ) {
            state.isRemoving = false
            state.error = action.payload.error
        },
        toggleModalWizard(
            state: InitialStateType,
            action: PayloadAction<{
                visible: boolean
                selectedBackgrounds?: IElement | null
            }>
        ) {
            state.isModalWizard = action.payload.visible
            state.selectedBackgrounds =
                action.payload.selectedBackgrounds ?? null
        }
    }
})

export const actions = settingsHomeBackgroundsSlice.actions

export default settingsHomeBackgroundsSlice.reducer
