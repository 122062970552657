import type { AxiosResponse } from 'axios'
import { del, get, patch, post } from '../../utils/api.ts'

export const fetchBackgrounds = (
    page: number,
    pageSize: number
): Promise<AxiosResponse<void>> =>
    get<void>('/admin/elements/main-background', {
        page,
        pageSize,
        sort: '-created_at',
        include: 'areas,defaultImageCollection'
    })

export const createBackgrounds = (
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> =>
    post<void>('/admin/elements/main-background', form)

export const editBackgrounds = (
    element_id: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> =>
    patch<void>(`/admin/elements/main-background/${element_id}`, form)

export const removeBackgrounds = (
    background_id: string
): Promise<AxiosResponse<void>> =>
    del<void>(`/admin/elements/main-background/${background_id}`)
