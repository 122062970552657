import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Checkbox } from 'antd'
import { DatePicker, Form, Input, Modal, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import RegionSelector from '../../containers/RegionSelector'
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { SortableList } from './SortableList'
import { DragHandle, SortableItem } from './SortableItem'
import YarmarkaBlockWizardModal from '../YarmarkaBlockWizardModal'
import BlocksSelector from '../../containers/BlocksSelector'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsFairs/slice.ts'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsFairsSelector } from '../../containers/SettingsFairs/selectors.ts'

dayjs.extend(utc)
dayjs.extend(timezone)

const MOSCOW_TIMEZONE = 'Europe/Moscow'

const { RangePicker } = DatePicker

const { TextArea } = Input

const { Title } = Typography

export interface IYarmarkaBlock {
    id: number
    title: string
    type?: 'events' | 'companies' | 'journal'
    icon?: string
    size?: 'small' | 'big'
    category?: string
    themes?: string[]
    urlCategory?: string
}

export default function YarmarkaWizardModal(): ReactElement {
    const [items, setItems] = useState<IYarmarkaBlock[]>([])
    const [location, setLocation] = useState<
        { label: string; value: string }[]
    >([])
    const [updateForm, setUpdateForm] = useState<number>(Date.now())

    const [selectedBlock, setSelectedBlock] = useState<number | false>(false)

    const [form] = useForm()

    const { isModalWizard, selectedFair, isSelectedCopied } = useAppSelector(
        settingsFairsSelector
    )

    const isEdit = selectedFair && !isSelectedCopied

    const dispatch = useDispatch()

    useEffect(() => {
        if (selectedFair) {
            form.setFieldsValue(selectedFair)
            if (Array.isArray(selectedFair.areas)) {
                setLocation(
                    selectedFair.areas.map(area => ({
                        label: area.name,
                        value: area.area_id
                    }))
                )
            }
            if (Array.isArray(selectedFair.data)) {
                for (const selected of selectedFair.data) {
                    if (selected.block) {
                        form.setFieldValue(selected.block, selected.value)
                    }
                    setUpdateForm(Date.now())
                }
                setItems(selectedFair.data.filter(item => !item.block))
            }
            form.setFieldValue('period', [
                dayjs(selectedFair.start_at as string),
                dayjs(selectedFair.end_at as string)
            ])
            form.setFieldValue('show_in_menu', selectedFair.show_in_menu)
            form.setFieldValue('show_counter', selectedFair.show_counter)
        } else {
            setItems([])
            setLocation([])
        }
    }, [selectedFair])

    const handleSendForm = (form: Record<string, unknown>): void => {
        if (!Array.isArray(form.period)) {
            return
        }
        const formatForm = {
            name: form.name,
            start_at: dayjs(form.period[0].toString())
                .tz(MOSCOW_TIMEZONE)
                .utc(),
            end_at: dayjs(form.period[1].toString()).tz(MOSCOW_TIMEZONE).utc(),
            areas: location.map(item => item.value),
            slug: form.slug,
            meta_h1: form.meta_h1,
            meta_title: form.meta_title,
            meta_description: form.meta_description,
            data: [
                {
                    block: 'block_top',
                    value: form.block_top
                },
                {
                    block: 'block_left',
                    value: form.block_left
                },
                {
                    block: 'block_partner',
                    value: form.block_partner
                },
                ...items
            ],
            show_in_menu: form.show_in_menu,
            show_counter: form.show_counter
        }
        if (isEdit) {
            dispatch(
                actions.edit({
                    fair_id: selectedFair.fair_id as string,
                    form: formatForm
                })
            )
        } else {
            dispatch(actions.create({ form: formatForm }))
        }
    }

    return (
        <Modal
            open={isModalWizard}
            onCancel={() => {
                if (typeof selectedBlock !== 'number') {
                    dispatch(
                        actions.toggleModalWizard({
                            visible: false
                        })
                    )
                    form.resetFields()
                }
            }}
            title={`${isEdit ? 'Редактирование' : 'Создание'} ярмарки`}
            okText={isEdit ? 'Сохранить' : 'Создать'}
            onOk={form.submit}
            style={{ top: 40 }}
        >
            <Form
                key={updateForm}
                form={form}
                layout={'vertical'}
                initialValues={{
                    location: []
                }}
                onFinish={handleSendForm}
            >
                <Form.Item
                    name={'name'}
                    style={{ marginBottom: 10 }}
                    label={'Название ярмарки'}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={'Название ярмарки'} />
                </Form.Item>
                <Form.Item
                    name={'period'}
                    style={{ marginBottom: 10 }}
                    label={'Период проведения'}
                    rules={[{ required: true }]}
                >
                    <RangePicker
                        showTime
                        format={'DD.MM.YYYY HH:mm'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    label={'Геолокация'}
                    name={'location'}
                    rules={[
                        {
                            required: true,
                            message: <></>,
                            validator: () =>
                                location.length > 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          new Error(
                                              'Необходимо выбрать локацию'
                                          )
                                      )
                        }
                    ]}
                >
                    <RegionSelector
                        isMultiple={true}
                        initialValue={location}
                        onChange={value =>
                            Array.isArray(value) ? setLocation(value) : null
                        }
                    />
                </Form.Item>
                <Form.Item
                    name={'slug'}
                    style={{ marginBottom: 10 }}
                    label={'Слаг адреса ярмарки'}
                    rules={[{ required: true }]}
                    help={'Пример: https://mamado.su/moskva/[slug]'}
                >
                    <Input placeholder={'Слаг адреса ярмарки'} />
                </Form.Item>
                <Form.Item
                    name={'meta_h1'}
                    style={{ marginBottom: 10 }}
                    label={'Meta H1'}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={'Meta H1'} />
                </Form.Item>
                <Form.Item
                    name={'meta_title'}
                    style={{ marginBottom: 10 }}
                    label={'Meta-title'}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={'Meta-title'} />
                </Form.Item>
                <Form.Item
                    name={'meta_description'}
                    style={{ marginBottom: 10 }}
                    label={'Meta-description'}
                    rules={[{ required: true }]}
                >
                    <TextArea placeholder={'Meta-description'} />
                </Form.Item>
                <Form.Item
                    name={'block_top'}
                    style={{ marginBottom: 10 }}
                    label={'Верхний блок'}
                >
                    <BlocksSelector />
                </Form.Item>
                <Form.Item
                    name={'block_left'}
                    label={'Рекламный блок слева'}
                    style={{ marginBottom: 10 }}
                >
                    <BlocksSelector />
                </Form.Item>
                <Title style={{ margin: '0 0 5px' }} level={5}>
                    {'Блоки на странице ярмарки'}
                </Title>
                <div
                    style={{
                        maxHeight: 300,
                        overflow: 'auto'
                    }}
                >
                    <SortableList
                        items={items}
                        onChange={setItems}
                        renderItem={item => (
                            <SortableItem id={item.id}>
                                <div style={{ marginRight: 'auto' }}>
                                    {item.title}
                                </div>
                                <Button
                                    size={'small'}
                                    icon={<EditOutlined />}
                                    onClick={() => setSelectedBlock(item.id)}
                                />
                                <Button
                                    type={'primary'}
                                    danger={true}
                                    size={'small'}
                                    icon={<DeleteOutlined />}
                                    style={{ margin: '0 0 0 10px' }}
                                    onClick={() =>
                                        setItems(prev =>
                                            prev.filter(
                                                block => block.id !== item.id
                                            )
                                        )
                                    }
                                />
                                <DragHandle />
                            </SortableItem>
                        )}
                    />
                </div>
                <Button
                    icon={<PlusOutlined />}
                    type={'primary'}
                    style={{ margin: '10px 0' }}
                    onClick={() =>
                        setItems(prev => [
                            ...prev,
                            {
                                id: prev.length + 1,
                                title: `Блок ярмарки №${prev.length + 1}`
                            }
                        ])
                    }
                >
                    {'Добавить блок'}
                </Button>
                <Form.Item
                    name={'block_partner'}
                    label={'Блок партнеров'}
                    style={{ marginBottom: 10 }}
                >
                    <BlocksSelector />
                </Form.Item>
                <Form.Item
                    name={'show_in_menu'}
                    valuePropName={'checked'}
                    style={{ marginBottom: 10 }}
                >
                    <Checkbox>{'Показывать кнопку в меню'}</Checkbox>
                </Form.Item>
                <Form.Item name={'show_counter'} valuePropName={'checked'}>
                    <Checkbox>
                        {'Показывать счетчик посещения ярмарки'}
                    </Checkbox>
                </Form.Item>
            </Form>
            <YarmarkaBlockWizardModal
                items={items}
                selectedBlock={selectedBlock}
                onClose={() => setSelectedBlock(false)}
                onSave={setItems}
            />
        </Modal>
    )
}
