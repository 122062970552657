import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, getContext, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
    CategoryRemovePayload,
    CreatePayload,
    CreateTagPayload,
    EditTagPayload,
    RemoveTagPayload,
    SendWizardPayload,
    SortCategoriesPayload,
    SortTagsPayload
} from './types.ts'
import type { CallEffect } from '@redux-saga/core/effects'

export function* handleFetch(): SagaIterator {
    try {
        const { data } = yield call(api.fetch)
        const { data: dataTags } = yield call(api.fetchTags)
        yield put(actions.fetchSuccess({ data, tags: dataTags.data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleSave(
    action: PayloadAction<CreatePayload>
): SagaIterator {
    try {
        yield call(api.save, action.payload.form)
        yield put(actions.saveSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.saveFailure({
                error: message
            })
        )
    }
}

export function* watchSave(): SagaIterator {
    yield takeEvery(actions.save, handleSave)
}

export function* handleSendWizard(
    action: PayloadAction<SendWizardPayload>
): SagaIterator {
    try {
        yield call(
            api.sendWizard,
            action.payload.form,
            action.payload.postCategoryId
        )
        yield put(actions.sendWizardSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.sendWizardFailure({
                error: message
            })
        )
    }
}

export function* watchSendWizard(): SagaIterator {
    yield takeEvery(actions.sendWizard, handleSendWizard)
}

export function* handleRemoveCategory(
    action: PayloadAction<CategoryRemovePayload>
): SagaIterator {
    try {
        yield call(api.removeCategory, action.payload.postCategoryId)
        yield put(actions.removeCategorySuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeCategoryFailure({
                error: message
            })
        )
    }
}

export function* watchRemoveCategory(): SagaIterator {
    yield takeEvery(actions.removeCategory, handleRemoveCategory)
}

export function* handleSortCategories(
    action: PayloadAction<SortCategoriesPayload>
): SagaIterator {
    try {
        const fetches: CallEffect[] = []
        for (const [index, category] of action.payload.categories.entries()) {
            fetches.push(yield call(api.sendWizard, { order: index }, category))
        }
        yield all(fetches)
        yield put(actions.sortCategoriesSuccess())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.sortCategoriesFailure({
                error: message
            })
        )
    }
}

export function* watchSortCategories(): SagaIterator {
    yield takeEvery(actions.sortCategories, handleSortCategories)
}

export function* handleCreateTag(
    action: PayloadAction<CreateTagPayload>
): SagaIterator {
    try {
        yield call(api.createTag, action.payload.form)
        yield put(actions.createTagSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCreateTag(): SagaIterator {
    yield takeEvery(actions.createTag, handleCreateTag)
}

export function* handleEditTag(
    action: PayloadAction<EditTagPayload>
): SagaIterator {
    try {
        yield call(api.editTag, action.payload.post_tag_id, action.payload.form)
        yield put(actions.editTagSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchEditTag(): SagaIterator {
    yield takeEvery(actions.editTag, handleEditTag)
}

export function* handleRemoveTag(
    action: PayloadAction<RemoveTagPayload>
): SagaIterator {
    try {
        yield call(api.removeTag, action.payload.post_tag_id)
        yield put(actions.removeTagSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchRemoveTag(): SagaIterator {
    yield takeEvery(actions.removeTag, handleRemoveTag)
}

export function* handleSortTags(
    action: PayloadAction<SortTagsPayload>
): SagaIterator {
    try {
        yield call(
            api.sortTags,
            action.payload.tags?.map(tag => tag.post_tag_id) ??
                action.payload.ids ??
                []
        )
        yield put(actions.sortTagsSuccess())

        if (action.payload.tags) {
            const message = yield getContext('message')
            message.open({
                type: 'success',
                content: 'Данные успешно сохранены!'
            })
        }

        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.sortTagsFailure({
                error: message
            })
        )
    }
}

export function* watchSortTags(): SagaIterator {
    yield takeEvery(actions.sortTags, handleSortTags)
}

export default function* watchSettingsTags(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchSave),
        fork(watchSendWizard),
        fork(watchRemoveCategory),
        fork(watchSortCategories),
        fork(watchFetchCreateTag),
        fork(watchFetchEditTag),
        fork(watchFetchRemoveTag),
        fork(watchSortTags)
    ])
}
