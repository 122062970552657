import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, getContext, put, takeEvery } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
    ClearAdminMessagePayload,
    CreatePayload,
    EditPayload,
    FetchPayload,
    UploadCoverPayload
} from './types.ts'
import * as effects from 'redux-saga/effects'
import * as api from './api.ts'
import type { createBrowserRouter } from 'react-router-dom'
import { actions } from './slice.ts'
import { formatApiError, sagaNotificationError } from '../../utils/helpers.ts'

export function* handleCreate(
    action: PayloadAction<CreatePayload>
): SagaIterator {
    try {
        yield effects.call(api.create, action.payload.form)
        const router: ReturnType<typeof createBrowserRouter> =
            yield getContext('router')
        void router.navigate('/blog')
        yield put(actions.createSuccess())
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.createFailure({
                error: message
            })
        )
    }
}

export function* watchCreate(): SagaIterator {
    yield takeEvery(actions.create, handleCreate)
}

export function* handleEdit(action: PayloadAction<EditPayload>): SagaIterator {
    try {
        yield effects.call(api.edit, action.payload.postId, action.payload.form)
        yield put(
            actions.editSuccess({ onSuccessEdit: action.payload.onSuccessEdit })
        )
        yield put(actions.fetch({ postId: action.payload.postId }))
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.editFailure({
                error: message
            })
        )
    }
}

export function* watchEdit(): SagaIterator {
    yield takeEvery(actions.edit, handleEdit)
}

export function* handleFetch(
    action: PayloadAction<FetchPayload>
): SagaIterator {
    try {
        const { data } = yield effects.call(api.fetch, action.payload.postId)
        yield put(actions.fetchSuccess({ data }))
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleUploadCover(
    action: PayloadAction<UploadCoverPayload>
): SagaIterator {
    try {
        const { data } = yield effects.call(
            api.uploadCover,
            action.payload.image
        )
        yield put(
            actions.uploadCoverSuccess({
                data
            })
        )
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.uploadCoverFailure({
                error: message
            })
        )
    }
}

export function* watchUploadCover(): SagaIterator {
    yield takeEvery(actions.uploadCover, handleUploadCover)
}

export function* handleClearAdminMessage(
    action: PayloadAction<ClearAdminMessagePayload>
): SagaIterator {
    try {
        yield effects.call(api.edit, action.payload.postId, {
            admin_message: null
        })
        yield put(actions.clearAdminMessageSuccess())
    } catch (error) {
        const { message, code } = formatApiError(error)
        yield call(sagaNotificationError, message, code)
        yield put(
            actions.clearAdminMessageFailure({
                error: message
            })
        )
    }
}

export function* watchClearAdminMessage(): SagaIterator {
    yield takeEvery(actions.clearAdminMessage, handleClearAdminMessage)
}

export default function* watchBlogWizard(): SagaIterator {
    yield all([
        fork(watchCreate),
        fork(watchEdit),
        fork(watchFetch),
        fork(watchUploadCover),
        fork(watchClearAdminMessage)
    ])
}
