import { useState, type ReactElement, type FC, useEffect } from 'react'
import {
    Button,
    Form,
    Modal,
    Row,
    Upload,
    DatePicker,
    type UploadProps,
    Input,
    Select
} from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { authorizationSelector } from '../../containers/Authorization/selectors.ts'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
    LazyLoadImage,
    type LazyLoadImageProps
} from 'react-lazy-load-image-component'
import RegionSelector from '../../containers/RegionSelector'
import { settingsBottomButtonSelector } from '../../containers/SettingsBottomButton/selectors.ts'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsBottomButton/slice.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import styles from './styles.module.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

const MOSCOW_TIMEZONE = 'Europe/Moscow'

const TypedLazyLoadImage = LazyLoadImage as FC<LazyLoadImageProps>

const { RangePicker } = DatePicker

export default function BottomButtonWizardModal(): ReactElement {
    const [image, setImage] = useState<{ id: number; url: string } | null>(null)
    const [location, setLocation] = useState<
        { label: string; value: string }[]
    >([])
    const [globalError, setGlobalError] = useState<string[]>([])

    const { token } = useAppSelector(authorizationSelector)

    const dispatch = useDispatch()

    const { selectedButton, isModalWizard } = useAppSelector(
        settingsBottomButtonSelector
    )

    const [form] = useForm()

    useEffect(() => {
        if (selectedButton) {
            if (Array.isArray(selectedButton.areas)) {
                setLocation(
                    selectedButton.areas.map(area => ({
                        label: area.name,
                        value: area.area_id
                    }))
                )
            }
            if (selectedButton.default_image) {
                setImage({
                    id: selectedButton.default_image.id,
                    url: selectedButton.default_image.url
                })
            }
            form.setFieldValue('name', selectedButton.name)
            form.setFieldValue('url', selectedButton.url)
            form.setFieldValue('exclude_urls', selectedButton.exclude_urls)
            form.setFieldValue('period', [
                dayjs(selectedButton.start_at as string),
                dayjs(selectedButton.end_at as string)
            ])
        } else {
            setImage(null)
            setLocation([])
        }
    }, [selectedButton])

    const handleChange: UploadProps['onChange'] = ({
        fileList: newFileList
    }) => {
        setImage(
            newFileList.at(-1)?.response
                ? {
                      id: newFileList.at(-1)?.response.id,
                      url: newFileList.at(-1)?.response.url
                  }
                : null
        )
        setGlobalError([])
    }

    const handleSendForm = (values: Record<string, unknown>) => {
        if (!image) {
            setGlobalError(['Необходимо выбрать изображение'])
            return
        }
        if (!Array.isArray(values.period)) {
            return
        }
        const formatForm = {
            name: values.name,
            url: values.url,
            start_at: dayjs(values.period[0].toString())
                .tz(MOSCOW_TIMEZONE)
                .utc(),
            end_at: dayjs(values.period[1].toString())
                .tz(MOSCOW_TIMEZONE)
                .utc(),
            areas: location.map(item => item.value),
            default_image: image ? { id: image.id } : undefined,
            exclude_urls: values.exclude_urls
        }
        if (selectedButton) {
            dispatch(
                actions.edit({
                    element_id: selectedButton.element_id as string,
                    form: formatForm
                })
            )
        } else {
            dispatch(actions.create({ form: formatForm }))
        }
    }

    return (
        <Modal
            open={isModalWizard}
            onCancel={() => {
                dispatch(actions.toggleModalWizard({ visible: false }))
                form.resetFields()
            }}
            title={`${selectedButton ? 'Редактирование' : 'Создание'} нижней кнопки`}
            okText={selectedButton ? 'Сохранить' : 'Создать'}
            onOk={form.submit}
            style={{ top: 40 }}
            destroyOnClose={true}
        >
            {image ? (
                <TypedLazyLoadImage
                    src={image.url}
                    width={'100%'}
                    alt={'Изображение кнопки'}
                    sizes={'(max-width: 768px) 100vw, (max-width: 1200px) 50vw'}
                />
            ) : null}
            <Row style={{ marginTop: image ? 10 : 15 }} justify={'center'}>
                <Upload
                    action={`${process.env.API_URL}/files`}
                    headers={{
                        Authorization: `Bearer ${token}`
                    }}
                    showUploadList={false}
                    multiple={false}
                    accept={'image/*'}
                    onChange={handleChange}
                >
                    <Button
                        type={'primary'}
                        icon={image ? <EditOutlined /> : <PlusOutlined />}
                    >
                        {`${image ? 'Изменить' : 'Выбрать'} изображение`}
                    </Button>
                </Upload>
            </Row>
            <Form
                form={form}
                layout={'vertical'}
                style={{ marginTop: 10 }}
                onFinish={handleSendForm}
            >
                <Form.Item
                    name={'name'}
                    style={{ margin: '10px 0' }}
                    label={'Название'}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={'Название'} />
                </Form.Item>
                <Form.Item
                    name={'url'}
                    style={{ margin: '10px 0' }}
                    label={'Ссылка кнопки'}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={'Ссылка кнопки'} />
                </Form.Item>
                <Form.Item
                    name={'period'}
                    style={{ margin: '10px 0' }}
                    label={'Период показа'}
                    rules={[{ required: true }]}
                >
                    <RangePicker
                        showTime
                        format={'DD.MM.YYYY HH:mm'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    label={'Геолокация'}
                    name={'location'}
                    rules={[
                        {
                            required: true,
                            message: undefined,
                            validator: () =>
                                location.length > 0
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          new Error(
                                              'Необходимо выбрать локацию'
                                          )
                                      )
                        }
                    ]}
                >
                    <RegionSelector
                        isMultiple={true}
                        initialValue={location}
                        onChange={value =>
                            Array.isArray(value) ? setLocation(value) : null
                        }
                    />
                </Form.Item>
                {globalError.length > 0 && (
                    <Form.ErrorList
                        className={styles.error}
                        errors={globalError}
                    />
                )}
                <Form.Item
                    name={'exclude_urls'}
                    style={{ margin: '10px 0' }}
                    label={'Не показывать на определенных путях'}
                    extra={
                        <>
                            {
                                'Текущий город в ссылке указывается, через [city].'
                            }
                            <br />
                            {'Пример ссылки: /[city]/yarmarka'}
                        </>
                    }
                >
                    <Select
                        mode={'tags'}
                        style={{ width: '100%' }}
                        placeholder={'Введите пути'}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
