import type { CSSProperties, HTMLAttributes, ReactElement } from 'react'
import { useEffect, useState, useMemo, cloneElement } from 'react'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
    Typography,
    Tabs,
    Button,
    Row,
    Modal,
    Form,
    Input,
    Upload,
    Popconfirm,
    message
} from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsTagsSelector } from './selectors.ts'
import { LoadingStep } from '../../components/LoadingStep'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import TagsWizard from '../../components/TagsWizard'
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    InboxOutlined
} from '@ant-design/icons'
import { upload } from '../../utils/api.ts'
import type { ICategory, ITag } from './types.ts'
import { useForm } from 'antd/es/form/Form'
import CompanyCategoriesSelector from '../CompanyCategoriesSelector'

interface DraggableTabPaneProps extends HTMLAttributes<HTMLDivElement> {
    'data-node-key': string
}

const { Title, Paragraph } = Typography
const { Dragger } = Upload
const { TextArea } = Input

interface UploadedImage {
    id: number
    logoThumbnail: string
    original: string
    thumbnail: string
    url: string
}

const DraggableTabNode = ({
    ...props
}: DraggableTabPaneProps): ReactElement => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: props['data-node-key']
        })

    const style: CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: 'move'
    }

    return cloneElement(props.children as ReactElement, {
        // @ts-ignore
        ref: setNodeRef,
        style,
        ...attributes,
        ...listeners
    })
}

export default function SettingsTags(): ReactElement {
    const [currentTempTags, setCurrentTempTags] = useState<string>('')
    const [tab, setTab] = useState('0')
    const [isModalHasChanges, setIsModalHasChanges] = useState<false | string>(
        false
    )
    const [uploadedImage, setUploadedImage] = useState<UploadedImage | null>(
        null
    )
    const [selectedCategory, setSelectedCategory] = useState('')
    const [editCategoryId, setEditCategoryId] = useState('')
    const [sortedItems, setSortedItems] = useState<
        (ICategory & { tags: ITag[]; key: string })[]
    >([])

    const [messageApi, contextHolder] = message.useMessage()

    const {
        categories,
        tempCategories,
        isFetching,
        isModalWizard,
        isCategoryRemove,
        isSortCategoriesProcess,
        lastFetchTime,
        isSendWizard
    } = useAppSelector(settingsTagsSelector)

    const currentCategory = useMemo(
        () => sortedItems.find(item => item.key === tab),
        [sortedItems, tab]
    )

    const hasChanges = useMemo(
        () => JSON.stringify(currentCategory?.tags ?? []) !== currentTempTags,
        [currentTempTags, currentCategory]
    )

    const [form] = useForm()

    const dispatch = useDispatch()

    useEffect(() => {
        if (isSortCategoriesProcess) {
            void messageApi.open({
                type: 'loading',
                content: 'Применение сортировки, не закрывайте окно...',
                duration: 0
            })
        } else {
            messageApi.destroy()
        }
    }, [isSortCategoriesProcess])

    useEffect(() => {
        setSortedItems([
            ...categories.map((item, index) => ({
                ...item,
                key: item.order?.toString() ?? (index + 1).toString()
            }))
        ])
        const findIndex = categories.findIndex(
            category =>
                category.post_category_id === currentCategory?.post_category_id
        )
        if (findIndex !== -1) {
            setTab(findIndex.toString())
        }
        setCurrentTempTags('')
    }, [lastFetchTime])

    useEffect(() => {
        const findTempCategory = tempCategories.find(
            item => item.post_category_id === currentCategory?.post_category_id
        )
        if (findTempCategory?.tags) {
            setCurrentTempTags(JSON.stringify(findTempCategory.tags))
        }
    }, [currentCategory, tempCategories])

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    const items = useMemo(
        () =>
            sortedItems.map(category => {
                return {
                    key: category.key,
                    label: category.name,
                    post_category_id: category.post_category_id,
                    children: (
                        <TagsWizard
                            selectedTags={category.tags}
                            color={'#ff57a6'}
                            postCategoryId={category.post_category_id}
                            setSortedItems={tags =>
                                setSortedItems(prev =>
                                    prev.map(prevItem => ({
                                        ...prevItem,
                                        tags:
                                            prevItem.post_category_id ===
                                            category.post_category_id
                                                ? tags.map((tag, index) => ({
                                                      ...tag,
                                                      sort: index + 1
                                                  }))
                                                : prevItem.tags
                                    }))
                                )
                            }
                        />
                    ),
                    icon: category.picture ? (
                        <img
                            width={20}
                            height={20}
                            style={{ marginBottom: -5 }}
                            src={category.picture.original}
                        />
                    ) : undefined
                }
            }),
        [sortedItems]
    )

    const sensor = useSensor(PointerSensor, {
        activationConstraint: { distance: 10 }
    })

    const onDragEnd = ({ active, over }: DragEndEvent): void => {
        if (active.id !== over?.id) {
            setSortedItems(prev => {
                const activeIndex = prev.findIndex(i => i.key === active.id)
                const overIndex = prev.findIndex(i => i.key === over?.id)
                const sortedItems = arrayMove(prev, activeIndex, overIndex)
                dispatch(
                    actions.sortCategories({
                        categories: sortedItems.map(
                            category => category.post_category_id
                        ),
                        activeTab: over!.id.toString()
                    })
                )
                return sortedItems
            })
        }
    }

    const handleUploadImage = async (file: File): Promise<false> => {
        const { data } = await upload<UploadedImage>(
            '/files',
            [
                {
                    field: 'file',
                    file
                }
            ],
            undefined,
            undefined,
            true
        )
        setUploadedImage(data)
        return false
    }

    const handleSendForm = (form: Record<string, unknown>): void => {
        dispatch(
            actions.sendWizard({
                postCategoryId: editCategoryId || undefined,
                form: {
                    ...form,
                    picture: uploadedImage
                        ? {
                              id: uploadedImage.id
                          }
                        : undefined,
                    company_category: selectedCategory
                        ? {
                              id: selectedCategory
                          }
                        : undefined,
                    order: editCategoryId ? undefined : sortedItems.length
                }
            })
        )
    }

    const handleOpenEdit = (category: ICategory): void => {
        setEditCategoryId(category.post_category_id)
        form.setFieldValue('name', category.name)
        form.setFieldValue('meta_h1', category.meta_h1)
        form.setFieldValue('meta_title', category.meta_title)
        form.setFieldValue('meta_description', category.meta_description)
        form.setFieldValue('description', category.description)
        form.setFieldValue('slug', category.slug)
        setUploadedImage(category.picture)
        setSelectedCategory(category.company_category?.companyCategoryId ?? '')
        dispatch(actions.toggleModalWizard(true))
    }

    const handleCloseModal = (): void => {
        setEditCategoryId('')
        form.setFieldValue('name', '')
        form.setFieldValue('meta_h1', '')
        form.setFieldValue('meta_title', '')
        form.setFieldValue('meta_description', '')
        form.setFieldValue('description', '')
        form.setFieldValue('slug', '')
        setUploadedImage(null)
        setSelectedCategory('')
        dispatch(actions.toggleModalWizard(false))
    }

    const handleRemoveCategory = (postCategoryId: string): void => {
        dispatch(actions.removeCategory({ postCategoryId }))
    }

    return (
        <>
            <Row justify={'space-between'}>
                <Title level={3} style={{ margin: 0 }}>
                    {'Темы журнала'}
                </Title>
                <div style={{ marginLeft: 'auto', marginBottom: 20 }}>
                    {categories.length > 0 ? (
                        <Button
                            style={{ marginRight: 10 }}
                            onClick={() =>
                                handleOpenEdit(sortedItems[parseInt(tab)])
                            }
                        >
                            {'Редактировать выбранную категорию журнала'}
                        </Button>
                    ) : null}
                    <Button
                        onClick={() =>
                            dispatch(actions.toggleModalWizard(true))
                        }
                    >
                        {'Добавить категорию журнала'}
                    </Button>
                </div>
            </Row>
            {categories.length === 0 && isFetching ? (
                <LoadingStep />
            ) : (
                <Tabs
                    key={items.map(item => item.key).join('')}
                    activeKey={tab}
                    style={{ marginTop: 10 }}
                    animated={{
                        inkBar: false,
                        tabPane: true
                    }}
                    onChange={value => {
                        if (hasChanges) {
                            setIsModalHasChanges(value)
                        } else {
                            setTab(value)
                        }
                    }}
                    renderTabBar={(tabBarProps, DefaultTabBar) => (
                        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                            <SortableContext
                                items={items.map(i => i.key)}
                                strategy={horizontalListSortingStrategy}
                            >
                                <DefaultTabBar {...tabBarProps}>
                                    {node => (
                                        // @ts-ignore
                                        <DraggableTabNode
                                            // @ts-ignore
                                            {...node.props}
                                            key={node.key}
                                        >
                                            {node}
                                        </DraggableTabNode>
                                    )}
                                </DefaultTabBar>
                            </SortableContext>
                        </DndContext>
                    )}
                    items={items}
                />
            )}
            <Paragraph style={{ marginTop: 20 }} type={'secondary'}>
                {'Сортировка тем в адресной строке в порядке создания тегов'}
            </Paragraph>
            <Modal
                open={typeof isModalHasChanges === 'string'}
                title={
                    <Row align={'middle'}>
                        <ExclamationCircleOutlined
                            style={{
                                fontSize: 22,
                                color: 'red',
                                marginRight: 10
                            }}
                        />{' '}
                        <Paragraph
                            style={{
                                margin: 0,
                                fontWeight: '600',
                                fontSize: 16
                            }}
                        >
                            {'Внимание'}
                        </Paragraph>
                    </Row>
                }
                okText={'Все равно перейти'}
                cancelText={'Остаться'}
                onOk={() => {
                    setIsModalHasChanges(false)
                    setTab(isModalHasChanges as string)
                }}
                onCancel={() => setIsModalHasChanges(false)}
            >
                <p>
                    {
                        'У вас остались не сохраненные изменения в текущей категории, все равно перейти в другую категорию?'
                    }
                </p>
            </Modal>
            <Modal
                open={isModalWizard}
                title={
                    editCategoryId
                        ? 'Редактирование категории журнала'
                        : 'Создание категории журнала'
                }
                onCancel={handleCloseModal}
                onClose={handleCloseModal}
                cancelButtonProps={{
                    style: {
                        display: 'none'
                    }
                }}
                okButtonProps={{
                    style: {
                        display: 'none'
                    }
                }}
            >
                <Form form={form} layout={'vertical'} onFinish={handleSendForm}>
                    <Form.Item
                        label={'Название категории журнала'}
                        name={'name'}
                        style={{ marginBottom: 10 }}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Пожлауйста заполните название категории журнала.'
                            }
                        ]}
                    >
                        <Input placeholder={'Название категории журнала'} />
                    </Form.Item>
                    <Form.Item
                        label={'Заголовок категории H1'}
                        name={'meta_h1'}
                        style={{ marginBottom: 10 }}
                    >
                        <Input placeholder={'Заголовок категории H1'} />
                    </Form.Item>
                    <Form.Item
                        label={'Описание'}
                        name={'description'}
                        style={{ marginBottom: 10 }}
                    >
                        <TextArea placeholder={'Описание'} />
                    </Form.Item>
                    <Form.Item
                        label={'Meta-title'}
                        name={'meta_title'}
                        style={{ marginBottom: 10 }}
                    >
                        <Input placeholder={'Meta-title'} />
                    </Form.Item>
                    <Form.Item
                        label={'Meta-description'}
                        name={'meta_description'}
                        style={{ marginBottom: 10 }}
                    >
                        <TextArea placeholder={'Meta-description'} />
                    </Form.Item>
                    <Form.Item
                        label={'Категория компании'}
                        style={{ marginBottom: 20 }}
                    >
                        <CompanyCategoriesSelector
                            value={selectedCategory}
                            onChange={setSelectedCategory}
                            isSingleMode={true}
                        />
                    </Form.Item>
                    <Form.Item
                        label={'Категория компании'}
                        name={'slug'}
                        style={{ marginBottom: 20 }}
                    >
                        <Input placeholder={'SEO слаг'} />
                    </Form.Item>
                    <Dragger
                        showUploadList={false}
                        accept={'image/*'}
                        multiple
                        beforeUpload={handleUploadImage}
                        style={{ marginBottom: 20 }}
                    >
                        {uploadedImage ? (
                            <img
                                width={30}
                                height={30}
                                src={uploadedImage.url}
                            />
                        ) : (
                            <>
                                <p className={'ant-upload-drag-icon'}>
                                    <InboxOutlined />
                                </p>
                                <p className={'ant-upload-text'}>
                                    {
                                        'Перенесите или нажмите для добавления логотипа'
                                    }
                                </p>
                            </>
                        )}
                    </Dragger>
                    <Row justify={'end'}>
                        {editCategoryId ? (
                            <Popconfirm
                                title={'Удалить категорию'}
                                description={
                                    'Вы уверены, что хотите удалить категорию?'
                                }
                                onConfirm={() =>
                                    handleRemoveCategory(editCategoryId)
                                }
                                okButtonProps={{
                                    loading: isCategoryRemove
                                }}
                                okText={'Да'}
                                cancelText={'Нет'}
                            >
                                <Button
                                    style={{ marginRight: 10 }}
                                    type={'primary'}
                                    danger={true}
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        ) : null}
                        <Button
                            loading={isSendWizard}
                            type={'primary'}
                            htmlType={'submit'}
                        >
                            {editCategoryId
                                ? 'Сохранить категорию журнала'
                                : 'Создать категорию журнала'}
                        </Button>
                    </Row>
                </Form>
            </Modal>
            {contextHolder}
        </>
    )
}
