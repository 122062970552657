import type { CSSProperties, FC, ReactElement } from 'react'
import { useState } from 'react'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex, Popconfirm, Tag, theme } from 'antd'
import type { ITag } from '../../containers/TagsSelector/types.ts'
import TagWizardModal from '../TagWizardModal'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsTags/slice.ts'
import type { DragEndEvent } from '@dnd-kit/core'
import {
    closestCenter,
    DndContext,
    MouseSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable
} from '@dnd-kit/sortable'

interface Props {
    selectedTags: ITag[]
    color: string
    postCategoryId: string
    setSortedItems: (tags: ITag[]) => void
}

const commonStyle: CSSProperties = {
    cursor: 'move',
    transition: 'unset'
}

interface DraggableTagProps {
    index: number
    tag: ITag
}

export default function TagsWizard({
    selectedTags,
    color,
    postCategoryId,
    setSortedItems
}: Props): ReactElement {
    const { token } = theme.useToken()

    const [selectedTag, setSelectedTag] = useState<ITag | undefined>()

    const dispatch = useDispatch()

    const sensors = useSensors(
        useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
    )

    const DraggableTag: FC<DraggableTagProps> = props => {
        const { tag, index } = props
        const {
            attributes,
            listeners,
            transform,
            transition,
            isDragging,
            setNodeRef
        } = useSortable({ id: index })

        const style = transform
            ? {
                  ...commonStyle,
                  transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
                  transition: isDragging ? 'unset' : transition
              }
            : commonStyle

        return (
            <Tag
                key={tag.post_tag_id}
                style={{
                    ...style,
                    background: color,
                    border: 'none',
                    color: '#FFF'
                }}
                onClose={e => {
                    e.preventDefault()
                }}
                ref={setNodeRef}
                {...attributes}
                {...listeners}
            >
                {tag.title}
                <EditOutlined
                    onClick={() => {
                        setSelectedTag(tag)
                        dispatch(actions.toggleTagWizardModal(true))
                    }}
                    style={{
                        marginLeft: 5,
                        cursor: 'pointer',
                        outline: 0
                    }}
                />
                <Popconfirm
                    placement={'topLeft'}
                    title={'Вы уверены, что хотите удалить тег?'}
                    description={
                        'Важна последовательность тегов, если удалить, некоторые страницы будут отдавать 404'
                    }
                    okText={'Да'}
                    cancelText={'Нет'}
                    onConfirm={() =>
                        dispatch(
                            actions.removeTag({
                                post_tag_id: tag.post_tag_id
                            })
                        )
                    }
                >
                    <CloseOutlined style={{ color: '#FFF', outline: 0 }} />
                </Popconfirm>
            </Tag>
        )
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        if (!over) {
            return
        }
        if (active.id !== over.id) {
            const oldIndex = selectedTags.findIndex(
                (_, index) => index + 1 === active.id
            )
            const newIndex = selectedTags.findIndex(
                (_, index) => index + 1 === over.id
            )

            const newSortedItems = arrayMove(selectedTags, oldIndex, newIndex)

            setSortedItems(newSortedItems)

            dispatch(
                actions.sortTags({
                    category_id: postCategoryId,
                    tags: newSortedItems
                })
            )
        }
    }

    return (
        <>
            <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCenter}
            >
                <SortableContext
                    items={selectedTags.map((_, index) => ({
                        id: index + 1
                    }))}
                    strategy={horizontalListSortingStrategy}
                >
                    <Flex gap={'4px 0'} wrap>
                        {selectedTags.map((item, index) => (
                            <DraggableTag
                                tag={item}
                                index={index + 1}
                                key={item.post_tag_id}
                            />
                        ))}
                        <Tag
                            style={{
                                height: 22,
                                background: token.colorBgContainer,
                                borderStyle: 'dashed',
                                cursor: 'pointer'
                            }}
                            icon={<PlusOutlined />}
                            onClick={() =>
                                dispatch(actions.toggleTagWizardModal(true))
                            }
                        >
                            {'Новый тег'}
                        </Tag>
                    </Flex>
                </SortableContext>
            </DndContext>
            <TagWizardModal
                selectedTag={selectedTag}
                postCategoryId={postCategoryId}
                onAfterClose={() => {
                    setSelectedTag(undefined)
                }}
            />
        </>
    )
}
