import { useEffect, useRef, useState } from 'react'
import type { ReactElement, RefObject, FC } from 'react'
import type { SwiperRef } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectFlip } from 'swiper/modules'
import { useDispatch } from 'react-redux'
import type { InputRef } from 'antd'
import { Button, Form, Row, Space } from 'antd'
import { useAppSelector } from '../../hooks/useAppSelector'
import type { IAddress, LogInPayload } from './types'
import { actions } from './slice'
import { authorizationSelector } from './selectors'
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    SafetyCertificateOutlined,
    SendOutlined,
    EditOutlined,
    LoginOutlined,
    UserAddOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
    SyncOutlined
} from '@ant-design/icons'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSearchParams } from 'react-router-dom'
import MaskedInput from 'antd-mask-input'
import type { AddressSuggestions } from 'react-dadata'
import type { ICityValue } from '../CitySelector'
import CitySelector from '../CitySelector'
import type { LazyLoadImageProps } from 'react-lazy-load-image-component'

const TypedLazyLoadImage = LazyLoadImage as FC<LazyLoadImageProps>

// Styles
import styles from './styles.module.scss'

// Image
import image from './images/image.png'

function FormNewPassword({
    refSwiper
}: {
    refSwiper: RefObject<SwiperRef | null>
}): ReactElement {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const { isProcessNewPassword, isNewPasswordSend } = useAppSelector(
        authorizationSelector
    )

    useEffect(() => {
        const $chatra = document.querySelector('#chatra')
        if ($chatra) {
            $chatra.remove()
        }
        dispatch(actions.resetAllProcess())
    }, [])

    const handleSendForm = (form: { 'first-password': string }) => {
        const token = searchParams.get('token')
        const email = searchParams.get('email')
        if (token && email) {
            dispatch(
                actions.sendNewPassword({
                    email,
                    token,
                    password: form['first-password']
                })
            )
        }
    }

    const refFirstPassword = useRef<HTMLInputElement>(null)
    const refSecondPassword = useRef<HTMLInputElement>(null)

    return (
        <Form
            name={'new-password'}
            layout={'vertical'}
            onFinish={handleSendForm}
            initialValues={{
                remember: true
            }}
        >
            {!isNewPasswordSend ? (
                <>
                    <Form.Item
                        name={'first-password'}
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста введите пароль!'
                            }
                        ]}
                        style={{ marginBottom: 8 }}
                    >
                        <div
                            onClick={() => refFirstPassword.current?.focus()}
                            className={`${styles['input-outer']} ${styles['input-outer__password']}`}
                        >
                            <input
                                type={'password'}
                                ref={refFirstPassword}
                                disabled={isProcessNewPassword}
                                placeholder={'Пароль'}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        name={'second-password'}
                        dependencies={['first-password']}
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста введите пароль!'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('first-password') ===
                                            value
                                    ) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        new Error('Вы ввели разные пароли!')
                                    )
                                }
                            })
                        ]}
                        style={{ marginBottom: 16 }}
                    >
                        <div
                            onClick={() => refSecondPassword.current?.focus()}
                            className={`${styles['input-outer']} ${styles['input-outer__password']}`}
                        >
                            <input
                                type={'password'}
                                ref={refSecondPassword}
                                disabled={isProcessNewPassword}
                                placeholder={'Пароль'}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 16 }}>
                        <Button
                            loading={isProcessNewPassword}
                            type={'primary'}
                            htmlType={'submit'}
                        >
                            {'Сохранить'}
                        </Button>
                    </Form.Item>
                </>
            ) : (
                <>
                    <Row justify={'center'}>
                        <SafetyCertificateOutlined
                            style={{
                                color: '#FFF',
                                fontSize: 45,
                                marginBottom: 20
                            }}
                        />
                    </Row>
                    <span className={styles.forgot__description}>
                        {
                            'Мы установили новый пароль, вернитесь ко входу, чтобы войти в вашу учетную запись с новым паролем.'
                        }
                    </span>
                </>
            )}
            <Button
                className={styles.register}
                style={{ marginBottom: 10 }}
                onClick={() => {
                    refSwiper.current?.swiper.slideTo(0, 1000)
                    setSearchParams({})
                }}
                icon={<ArrowLeftOutlined />}
            >
                {'Вернуться ко входу'}
            </Button>
        </Form>
    )
}

function FormRecovery({
    refSwiper
}: {
    refSwiper: RefObject<SwiperRef | null>
}): ReactElement {
    const [emailInput, setEmailInput] = useState('')

    const dispatch = useDispatch()

    const { isProcessForgot, isForgotSend } = useAppSelector(
        authorizationSelector
    )

    const handleSendForm = (form: { email: string }) => {
        setEmailInput(form.email)
        dispatch(actions.sendForgot({ email: form.email }))
    }

    const refEmail = useRef<HTMLInputElement>(null)

    return (
        <Form name={'recovery'} layout={'vertical'} onFinish={handleSendForm}>
            {!isForgotSend ? (
                <>
                    <Form.Item
                        name={'email'}
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста введите E-mail!'
                            }
                        ]}
                        style={{ marginBottom: 8 }}
                    >
                        <div
                            onClick={() => refEmail.current?.focus()}
                            className={`${styles['input-outer']} ${styles['input-outer__email']}`}
                        >
                            <input
                                ref={refEmail}
                                disabled={isProcessForgot}
                                placeholder={'Email'}
                            />
                        </div>
                    </Form.Item>
                    <span className={styles.forgot__description}>
                        {
                            'На указанную почту будет отправлена ссылка.\nПо номеру телефона восстановить пароль можно через мобильное приложение Mamado.'
                        }
                    </span>
                    <Form.Item style={{ marginBottom: 16 }}>
                        <Button
                            loading={isProcessForgot}
                            type={'primary'}
                            htmlType={'submit'}
                            icon={<SyncOutlined />}
                        >
                            {'Восстановить'}
                        </Button>
                    </Form.Item>
                </>
            ) : (
                <>
                    <Row justify={'center'}>
                        <SendOutlined
                            style={{
                                color: '#FFF',
                                fontSize: 45,
                                marginBottom: 20
                            }}
                        />
                    </Row>
                    <span className={styles.forgot__description}>
                        {`Проверьте вашу почту ${emailInput}.\nМы отправили вам письмо.`}
                    </span>
                </>
            )}
            <Button
                className={styles.register}
                style={{ marginBottom: 10 }}
                onClick={() => {
                    refSwiper.current?.swiper.slideTo(0, 1000)
                    setTimeout(() => {
                        setEmailInput('')
                        if (isForgotSend) {
                            dispatch(actions.resetSendForgot())
                        }
                    }, 1000)
                }}
                icon={<ArrowLeftOutlined />}
            >
                {'Вернуться ко входу'}
            </Button>
        </Form>
    )
}

function FormComponent({
    register,
    refSwiper,
    registerEmail,
    setRegisterEmail
}: {
    register?: boolean
    refSwiper: RefObject<SwiperRef | null>
    registerEmail?: string
    setRegisterEmail?: (email: string) => void
}): ReactElement {
    const [passwordIsVisible, setPasswordIsVisible] = useState(false)
    const [passwordConfirmIsVisible, setPasswordConfirmIsVisible] =
        useState(false)
    const [selectedAddress, setSelectedAddress] = useState<ICityValue>()

    const dispatch = useDispatch()

    const { isProcessLogIn, isProcessRegister } = useAppSelector(
        authorizationSelector
    )

    const [formInstance] = Form.useForm()

    const refEmail = useRef<HTMLInputElement>(null)
    const refPhone = useRef<InputRef>(null)
    const refPassword = useRef<HTMLInputElement>(null)
    const refPasswordConfirm = useRef<HTMLInputElement>(null)
    const refLocation = useRef<AddressSuggestions>(null)

    const setInputError = (name: string, error: string) =>
        formInstance.setFields([
            {
                name: name,
                value: formInstance.getFieldValue(name),
                errors: [error]
            }
        ])

    const clearInputError = (name: string) =>
        formInstance.setFields([
            {
                name: name,
                value: formInstance.getFieldValue(name),
                errors: []
            }
        ])

    useEffect(() => {
        if (register && registerEmail && refEmail.current) {
            refEmail.current.value = registerEmail
            refPassword.current?.focus()
        }
    }, [registerEmail])

    const handleSendForm = (form: LogInPayload) => {
        if (register) {
            let phone: undefined | string = undefined
            const address: IAddress = {
                type: 'home',
                caption: '',
                address: '',
                location: {
                    lat: '',
                    lng: ''
                }
            }

            if (typeof form.phone === 'string' && form.phone.length > 0) {
                if (form.phone.length === 10) {
                    phone = `+7${form.phone}`
                    clearInputError('phone')
                } else {
                    setInputError('phone', 'Телефон введен не верно!')
                    return
                }
            } else {
                clearInputError('phone')
            }

            if (selectedAddress) {
                const location = selectedAddress.location.split('-')

                if (location.length !== 2) {
                    setInputError('location', 'Укажите адрес точнее!')
                    return
                }
                address.caption = selectedAddress.caption
                address.address = selectedAddress.value
                address.location.lat = location[0] || ''
                address.location.lng = location[1] || ''
            } else {
                setInputError('location', 'Введите свой город!')
                return
            }

            dispatch(
                actions.register({
                    email: form.username,
                    phone,
                    password: form.password,
                    address
                })
            )
        } else {
            dispatch(
                actions.logIn({
                    ...form,
                    btn: (
                        <Space>
                            <Button
                                type={'link'}
                                size={'small'}
                                onClick={() => {
                                    refSwiper.current?.swiper.slideTo(1, 1000)
                                    if (setRegisterEmail) {
                                        const email =
                                            formInstance.getFieldValue(
                                                'username'
                                            )
                                        setRegisterEmail(email)
                                    }
                                }}
                                style={{ color: '#ff57a5' }}
                            >
                                {'Перейти к регистрации'}
                            </Button>
                        </Space>
                    )
                })
            )
        }
    }

    // const handleOpenVkAuthorization = () => {
    //     window.VK.Auth.login((r: Record<string, Record<string, unknown>>) => {
    //         if (r.session) {
    //             // let username = r.session.user.first_name;
    //             console.log('Good: ', r.session.user)
    //         } else {
    //             console.log('Bad: ', r)
    //         }
    //     })
    // }

    return (
        <Form
            form={formInstance}
            name={register ? 'register' : 'authorization'}
            layout={'vertical'}
            onFinish={handleSendForm}
            initialValues={{
                remember: true
            }}
        >
            <Form.Item
                name={'username'}
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста введите E-mail!'
                    },
                    {
                        type: 'email',
                        message: 'E-mail введен не верно!'
                    }
                ]}
                style={{ marginBottom: 8 }}
            >
                <div
                    onClick={() => refEmail.current?.focus()}
                    className={`${styles['input-outer']} ${styles['input-outer__email']}`}
                >
                    <input
                        ref={refEmail}
                        disabled={isProcessLogIn}
                        placeholder={'Email'}
                    />
                </div>
            </Form.Item>
            {register ? (
                <Form.Item name={'phone'} style={{ marginBottom: 8 }}>
                    <div
                        onClick={() => refPhone.current?.focus()}
                        className={`${styles['input-outer']} ${styles['input-outer__phone']}`}
                    >
                        <MaskedInput
                            ref={refPhone}
                            disabled={isProcessLogIn}
                            placeholder={'Номер телефона'}
                            mask={'+7 (000) 000-00-00'}
                            onChange={event => {
                                const phoneValue = event.unmaskedValue
                                if (
                                    phoneValue.length === 0 ||
                                    phoneValue.length === 10
                                ) {
                                    clearInputError('phone')
                                } else {
                                    setInputError(
                                        'phone',
                                        'Телефон введен не верно!'
                                    )
                                }
                                formInstance.setFieldValue('phone', phoneValue)
                            }}
                        />
                    </div>
                </Form.Item>
            ) : null}
            <Form.Item
                name={'password'}
                style={{ marginBottom: 8 }}
                rules={[
                    {
                        required: true,
                        message: 'Пожалуйста введите пароль!'
                    }
                ]}
            >
                <div
                    onClick={() => refPassword.current?.focus()}
                    className={`${styles['input-outer']} ${styles['input-outer__password']}`}
                >
                    <input
                        ref={refPassword}
                        disabled={isProcessLogIn}
                        type={passwordIsVisible ? 'text' : 'password'}
                        placeholder={'Пароль'}
                    />
                    {passwordIsVisible ? (
                        <div
                            onClick={() => setPasswordIsVisible(false)}
                            className={styles['password-outer']}
                        >
                            <EyeOutlined className={styles['password-eye']} />
                        </div>
                    ) : (
                        <div
                            onClick={() => setPasswordIsVisible(true)}
                            className={styles['password-outer']}
                        >
                            <EyeInvisibleOutlined
                                className={styles['password-eye']}
                            />
                        </div>
                    )}
                </div>
            </Form.Item>
            {register ? (
                <>
                    <Form.Item
                        name={'confirm'}
                        dependencies={['password']}
                        style={{ marginBottom: 8 }}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Повторите пароль!'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        new Error('Пароли не совпадают!')
                                    )
                                }
                            })
                        ]}
                    >
                        <div
                            onClick={() => refPasswordConfirm.current?.focus()}
                            className={`${styles['input-outer']} ${styles['input-outer__password']}`}
                        >
                            <input
                                ref={refPasswordConfirm}
                                disabled={isProcessLogIn}
                                type={
                                    passwordConfirmIsVisible
                                        ? 'text'
                                        : 'password'
                                }
                                placeholder={'Повторите пароль'}
                            />
                            {passwordConfirmIsVisible ? (
                                <div
                                    onClick={() =>
                                        setPasswordConfirmIsVisible(false)
                                    }
                                    className={styles['password-outer']}
                                >
                                    <EyeOutlined
                                        className={styles['password-eye']}
                                    />
                                </div>
                            ) : (
                                <div
                                    onClick={() =>
                                        setPasswordConfirmIsVisible(true)
                                    }
                                    className={styles['password-outer']}
                                >
                                    <EyeInvisibleOutlined
                                        className={styles['password-eye']}
                                    />
                                </div>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item name={'location'} style={{ marginBottom: 12 }}>
                        <div
                            className={`${styles['input-outer']} ${styles['input-outer__location']}`}
                            style={
                                selectedAddress
                                    ? { cursor: 'default' }
                                    : undefined
                            }
                        >
                            <CitySelector
                                disabled={!!selectedAddress}
                                onChange={setSelectedAddress}
                            />
                            {selectedAddress ? (
                                <div
                                    onClick={() => {
                                        setSelectedAddress(undefined)
                                        refLocation.current?.setInputValue('')
                                        refLocation.current?.focus()
                                    }}
                                    className={styles['password-outer']}
                                >
                                    <EditOutlined
                                        className={styles['password-eye']}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </Form.Item>
                </>
            ) : (
                <span
                    onClick={() => refSwiper.current?.swiper.slideTo(2, 1000)}
                    className={styles.forgot}
                >
                    {'Забыли пароль?'}
                </span>
            )}
            <Form.Item style={{ marginBottom: 16 }}>
                <Button
                    loading={register ? isProcessRegister : isProcessLogIn}
                    type={'primary'}
                    htmlType={'submit'}
                    icon={register ? <UserAddOutlined /> : <LoginOutlined />}
                >
                    {register
                        ? 'Зарегистрироваться'
                        : 'Войти в панель управления'}
                </Button>
            </Form.Item>
            <Button
                className={styles.register}
                style={{ marginBottom: 16 }}
                onClick={() =>
                    register
                        ? refSwiper.current?.swiper.slidePrev(1000)
                        : refSwiper.current?.swiper.slideNext(1000)
                }
                icon={register ? <ArrowLeftOutlined /> : null}
            >
                {register ? 'Вернуться ко входу' : 'Перейти к регистрации'}
                {register ? null : <ArrowRightOutlined />}
            </Button>
            {/*<Row align={'middle'} justify={'center'}>*/}
            {/*    <Button*/}
            {/*        onClick={handleOpenVkAuthorization}*/}
            {/*        type={'primary'}*/}
            {/*        icon={*/}
            {/*            <FontAwesomeIcon icon={faVk} style={{ fontSize: 20 }} />*/}
            {/*        }*/}
            {/*        style={{ width: 40, height: 40 }}*/}
            {/*    />*/}
            {/*</Row>*/}
            <Row justify={'center'}>
                <a
                    className={styles.oferta}
                    href={'https://mamado.su/privacy-policy'}
                    target={'_blank'}
                    rel={'noreferrer'}
                >
                    {`${
                        register ? 'Регистрируясь' : 'Авторизуясь'
                    } вы соглашаетесь с условиями публичной оферты`}
                </a>
            </Row>
        </Form>
    )
}

export default function Authorization(): ReactElement {
    const [registerEmail, setRegisterEmail] = useState('')
    const refSwiper = useRef<SwiperRef | null>(null)

    const [searchParams] = useSearchParams()

    const { isForgotSend } = useAppSelector(authorizationSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        const token = searchParams.get('token')
        const email = searchParams.get('email')

        if (token && email && refSwiper.current) {
            refSwiper.current.swiper.slideTo(3, 0)
        }
    }, [searchParams])

    useEffect(() => {
        if (isForgotSend) {
            dispatch(actions.resetSendForgot())
        }
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles['wrapper__left']}>
                <TypedLazyLoadImage
                    draggable={false}
                    src={image}
                    title={'MAMADO'}
                />
            </div>
            <div className={styles['wrapper__right']}>
                <div className={styles['wrapper-form']}>
                    <div className={styles.logo} />
                    <Swiper
                        autoHeight={true}
                        ref={refSwiper}
                        effect={'flip'}
                        allowTouchMove={false}
                        grabCursor={false}
                        draggable={false}
                        centeredSlides={true}
                        centeredSlidesBounds={true}
                        simulateTouch={false}
                        modules={[EffectFlip]}
                        className={'swiper-hide-not-active'}
                        flipEffect={{
                            slideShadows: false
                        }}
                        style={{ width: '100%' }}
                    >
                        <SwiperSlide className={styles.centered}>
                            <span className={styles['slide-title']}>
                                {'Вход'}
                            </span>
                            <FormComponent
                                refSwiper={refSwiper}
                                setRegisterEmail={setRegisterEmail}
                            />
                        </SwiperSlide>
                        <SwiperSlide className={styles.centered}>
                            <span className={styles['slide-title']}>
                                {'Регистрация'}
                            </span>
                            <FormComponent
                                registerEmail={registerEmail}
                                refSwiper={refSwiper}
                                register={true}
                            />
                        </SwiperSlide>
                        <SwiperSlide className={styles.centered}>
                            <span className={styles['slide-title']}>
                                {'Восстановление пароля'}
                            </span>
                            <FormRecovery refSwiper={refSwiper} />
                        </SwiperSlide>
                        <SwiperSlide className={styles.centered}>
                            <span className={styles['slide-title']}>
                                {'Введите новый пароль'}
                            </span>
                            <FormNewPassword refSwiper={refSwiper} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
