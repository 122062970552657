import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type {
    InitialStateType,
    FetchPayload,
    FetchSuccessPayload,
    FetchFailurePayload
} from './types'

const initialState: InitialStateType = {
    categories: [],
    tags: [],
    isFetching: false,
    error: null
}

const companyTagsSelectorSlice = createSlice({
    name: 'companyTagsSelector',
    initialState,
    reducers: {
        fetch(state: InitialStateType, _action: PayloadAction<FetchPayload>) {
            state.isFetching = true
            state.error = null
        },
        fetchSuccess(
            state: InitialStateType,
            action: PayloadAction<FetchSuccessPayload>
        ) {
            state.categories = action.payload.categories
            state.tags = action.payload.tags
            state.isFetching = false
        },
        fetchFailure(
            state: InitialStateType,
            action: PayloadAction<FetchFailurePayload>
        ) {
            state.isFetching = false
            state.error = action.payload.error
        }
    }
})

export const actions = companyTagsSelectorSlice.actions

export default companyTagsSelectorSlice.reducer
