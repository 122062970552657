import type { AxiosResponse } from 'axios'
import { del, get, patch, post } from '../../utils/api.ts'

export const fetchButtons = (
    page: number,
    pageSize: number
): Promise<AxiosResponse<void>> =>
    get<void>('/admin/elements/bottom-button', {
        page,
        pageSize,
        sort: '-created_at',
        include: 'areas,defaultImage'
    })

export const createButton = (
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> =>
    post<void>('/admin/elements/bottom-button', form)

export const editButton = (
    element_id: string,
    form: Record<string, unknown>
): Promise<AxiosResponse<void>> =>
    patch<void>(`/admin/elements/bottom-button/${element_id}`, form)

export const removeButton = (
    background_id: string
): Promise<AxiosResponse<void>> =>
    del<void>(`/admin/elements/bottom-button/${background_id}`)
