import type { ReactElement } from 'react'
import { useEffect } from 'react'
import type { SelectProps } from 'antd'
import { TreeSelect } from 'antd'
import { useDispatch } from 'react-redux'
import { actions } from './slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { companyTagsSelector } from './selectors.ts'

const { SHOW_PARENT } = TreeSelect

export default function CompanyTagsSelector(
    props: { filterCategory?: string } & SelectProps
): ReactElement {
    const dispatch = useDispatch()

    const { categories, tags, isFetching } = useAppSelector(companyTagsSelector)

    useEffect(() => {
        dispatch(actions.fetch())
    }, [])

    return (
        <TreeSelect
            {...props}
            value={isFetching ? undefined : props.value}
            placeholder={'Выберите темы категории'}
            allowClear={true}
            disabled={isFetching}
            loading={isFetching}
            treeData={categories
                .filter(category =>
                    props.filterCategory
                        ? category.companyCategoryId === props.filterCategory
                        : true
                )
                .map((category, index) => ({
                    title: category.name,
                    value: category.companyCategoryId,
                    key: `${index}`,
                    checkable: false,
                    children: tags
                        .filter(
                            tag =>
                                tag.company_category_id ===
                                category.companyCategoryId
                        )
                        .map((tag, tagIndex) => ({
                            title: tag.title,
                            value: tag.company_tag_id,
                            key: `${index}-${tagIndex}`
                        }))
                }))
                .filter(category => category.children.length > 0)}
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
        />
    )
}
