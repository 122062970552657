import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { Checkbox, DatePicker, Form, Input, Modal } from 'antd'
import type { ITag } from '../../containers/TagsSelector/types.ts'
import { useForm } from 'antd/es/form/Form'
import { useDispatch } from 'react-redux'
import { actions } from '../../containers/SettingsTags/slice.ts'
import { useAppSelector } from '../../hooks/useAppSelector.ts'
import { settingsTagsSelector } from '../../containers/SettingsTags/selectors.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const MOSCOW_TIMEZONE = 'Europe/Moscow'

interface Props {
    selectedTag: ITag | undefined
    onAfterClose: () => void
    postCategoryId: string
}

export default function TagWizardModal({
    selectedTag,
    onAfterClose,
    postCategoryId
}: Props): ReactElement {
    const dispatch = useDispatch()

    const [form] = useForm()

    const { isModalTagWizard, isCreateTagProcess, isEditTagProcess } =
        useAppSelector(settingsTagsSelector)

    useEffect(() => {
        if (selectedTag) {
            form.setFieldsValue({
                ...selectedTag,
                deactivation_date: selectedTag.deactivation_date
                    ? dayjs(selectedTag.deactivation_date).tz(MOSCOW_TIMEZONE)
                    : undefined
            })
        } else {
            form.resetFields()
        }
    }, [selectedTag])

    const handleSendForm = (form: Record<string, unknown>) => {
        if (selectedTag) {
            dispatch(
                actions.editTag({
                    post_tag_id: selectedTag.post_tag_id,
                    form: {
                        ...form,
                        deactivation_date: dayjs.isDayjs(form.deactivation_date)
                            ? dayjs(form.deactivation_date.format())
                                  .tz(
                                      MOSCOW_TIMEZONE,
                                      !selectedTag.deactivation_date
                                  )
                                  .utc()
                            : null,
                        post_category_id: postCategoryId
                    }
                })
            )
        } else {
            dispatch(
                actions.createTag({
                    form: {
                        ...form,
                        deactivation_date: dayjs.isDayjs(form.deactivation_date)
                            ? dayjs(form.deactivation_date.format())
                                  .tz(MOSCOW_TIMEZONE, true)
                                  .utc()
                            : null,
                        post_category_id: postCategoryId
                    }
                })
            )
        }
    }

    return (
        <Modal
            open={isModalTagWizard}
            title={`${selectedTag ? 'Редактирование' : 'Создание'} тега`}
            onCancel={() => {
                setTimeout(onAfterClose, 200)
                dispatch(actions.toggleTagWizardModal(false))
            }}
            afterClose={() => form.resetFields()}
            okText={selectedTag ? 'Редактировать' : 'Создать'}
            onOk={form.submit}
            okButtonProps={{
                loading: isCreateTagProcess || isEditTagProcess
            }}
        >
            <Form layout={'vertical'} form={form} onFinish={handleSendForm}>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'title'}
                    label={'Название тега'}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите название тега!'
                        }
                    ]}
                >
                    <Input placeholder={'Название тега'} />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'slug'}
                    label={'Слаг тега'}
                    normalize={value =>
                        value
                            .replace(/[^a-zA-Z0-9-]+/g, '-')
                            .replace(/--+/g, '-')
                            .replace(/^-+/g, '')
                            .toLowerCase()
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста введите слаг тега!'
                        }
                    ]}
                >
                    <Input
                        placeholder={'Слаг тега'}
                        onBlur={() => {
                            const value = form.getFieldValue('slug')
                            if (typeof value === 'string') {
                                form.setFieldValue(
                                    'slug',
                                    value.replace(/-$/, '')
                                )
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'meta_h1'}
                    label={'H1 заголовок категории'}
                >
                    <Input placeholder={'H1 заголовок категории'} />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'description'}
                    label={'Описание категории'}
                >
                    <Input.TextArea placeholder={'Описание категории'} />
                </Form.Item>
                <Form.Item
                    style={{ marginBottom: 10 }}
                    name={'meta_title'}
                    label={'Meta-title'}
                >
                    <Input placeholder={'Meta-title'} />
                </Form.Item>
                <Form.Item
                    name={'meta_description'}
                    label={'Meta-description'}
                    style={{ marginBottom: 10 }}
                >
                    <Input.TextArea placeholder={'Meta-description'} />
                </Form.Item>
                <Form.Item
                    name={'is_hidden'}
                    valuePropName={'checked'}
                    style={{ marginBottom: 10 }}
                >
                    <Checkbox>{'Скрыть тег'}</Checkbox>
                </Form.Item>
                <Form.Item
                    name={'deactivation_date'}
                    label={'Дата снятия с публикации'}
                    tooltip={'Время необходимо указывать по Москве'}
                >
                    <DatePicker
                        allowClear={true}
                        showTime={true}
                        format={'DD.MM.YYYY HH:mm'}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
