import type { SagaIterator } from '@redux-saga/core'
import { all, call, fork, getContext, put, takeEvery } from 'redux-saga/effects'
import * as api from './api'
import { actions } from './slice'
import { formatApiError, sagaNotificationError } from '../../utils/helpers'
import type { PayloadAction } from '@reduxjs/toolkit'
import type {
    CreateTagPayload,
    EditTagPayload,
    RemoveTagPayload,
    SortTagsPayload
} from './types.ts'

export function* handleFetch(): SagaIterator {
    try {
        const { data } = yield call(api.fetchTags)
        yield put(actions.fetchSuccess({ tags: data.data }))
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.fetchFailure({
                error: message
            })
        )
    }
}

export function* watchFetch(): SagaIterator {
    yield takeEvery(actions.fetch, handleFetch)
}

export function* handleCreateTag(
    action: PayloadAction<CreateTagPayload>
): SagaIterator {
    try {
        const { data } = yield call(api.createTag, action.payload.form)
        yield put(actions.createTagSuccess())
        yield put(
            actions.sortTags({
                ids: [...action.payload.sortedTags, data.event_tag_id]
            })
        )
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.createTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchCreateTag(): SagaIterator {
    yield takeEvery(actions.createTag, handleCreateTag)
}

export function* handleEditTag(
    action: PayloadAction<EditTagPayload>
): SagaIterator {
    try {
        yield call(
            api.editTag,
            action.payload.event_tag_id,
            action.payload.form
        )
        yield put(actions.editTagSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.editTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchEditTag(): SagaIterator {
    yield takeEvery(actions.editTag, handleEditTag)
}

export function* handleRemoveTag(
    action: PayloadAction<RemoveTagPayload>
): SagaIterator {
    try {
        yield call(api.removeTag, action.payload.post_tag_id)
        yield put(actions.removeTagSuccess())
        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.removeTagFailure({
                error: message
            })
        )
    }
}

export function* watchFetchRemoveTag(): SagaIterator {
    yield takeEvery(actions.removeTag, handleRemoveTag)
}

export function* handleSortTags(
    action: PayloadAction<SortTagsPayload>
): SagaIterator {
    try {
        yield call(
            api.sortTags,
            action.payload.tags?.map(tag => tag.event_tag_id) ??
                action.payload.ids ??
                []
        )
        yield put(actions.sortTagsSuccess())

        if (action.payload.tags) {
            const message = yield getContext('message')
            message.open({
                type: 'success',
                content: 'Данные успешно сохранены!'
            })
        }

        yield put(actions.fetch())
    } catch (error) {
        const { message } = formatApiError(error)
        yield call(sagaNotificationError, message, error)
        yield put(
            actions.sortTagsFailure({
                error: message
            })
        )
    }
}

export function* watchSortTags(): SagaIterator {
    yield takeEvery(actions.sortTags, handleSortTags)
}

export default function* watchSettingsEventsTags(): SagaIterator {
    yield all([
        fork(watchFetch),
        fork(watchFetchCreateTag),
        fork(watchFetchEditTag),
        fork(watchFetchRemoveTag),
        fork(watchSortTags)
    ])
}
